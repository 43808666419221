import { useEffect, useState } from "react";

const ValueBox = ({ backgroundColor, textColor, value, secondary, label, uri, horizontal, previousValue }) => {

  const [currentValue, setCurrentValue] = useState(value);
  const [animclass, setAnimclass] = useState();

  useEffect(() => {
    if(value) {
      setCurrentValue(currentValue => {
        if(currentValue !== value) {
          setAnimclass('opacity-0');
          setTimeout(() => {
            setAnimclass('opacity-1');
          }, 250);
        }
        return(value);
      });
    }
  }, [value]);

  const gotoUri = () => {
    window.open(`/#/leave/${encodeURIComponent(uri)}`);
  }

  return(
    <div 
      className={`p-2 ${uri ? 'cursor-pointer' : ''} h-full w-full flex relative`}
    >
      <div className={`flex-1 ${backgroundColor} flex justify-center items-center rounded-md overflow-hidden overflow-ellipsis `} >
        <div 
          onClick={gotoUri} 
          className={`${animclass} transition-opacity ease-in duration-250 overflow-hidden overflow-ellipsis text-center p-2 text-text1-base flex-1 relative`}
        >
          {label && (<><span className="text-text1-base font-bold">{label}</span><br /></>)}
          <span className={`${textColor} font-bold`}>{currentValue}</span>{horizontal && secondary && (<br />)}{secondary && (<span className={`${!horizontal ? 'ml-2': ''}`}>{secondary}</span>)}
        </div>
      </div>
    </div>    
  )
}

const OddsBox = ({ odds, value, secondary, label, uri, horizontal }) => {

  if(!odds || !odds.values) return(<></>);

  let v = '';
  try {
    v = odds.values.find(v => v.name.toLowerCase() === value.toLowerCase());
    if(!v) {
      return(
        <div className="w-full h-full flex justify-center items-center p-2">
          <div className="bg-secondary4-base w-full h-full flex justify-center items-center p-2 rounded-md">
            n/a
          </div>
        </div>
      );
    }
  } catch(e) {
    return(<>err</>);
  }

  const vFromHistory = (bookmakerOdds) => {
    const { history } = bookmakerOdds;
    if(!history || history.length < 2) return(0);
     return(history[1].value - bookmakerOdds.value)
  } 

  const previousValue = (bookmakerOdds) => {
    const { history } = bookmakerOdds.find(v => v.name.toLowerCase() === value.toLowerCase());
    if(!history || history.length < 2) return(null);
    return(history[1].value);
  }

  const bgFromHistory = (bookmakerOdds) => {
    let v = vFromHistory(bookmakerOdds.filter(v => v.name.toLowerCase() === value.toLowerCase())[0]);
    if(v < 0)
      return('bg-secondary1-transparent')       
    else if(v > 0)
      return('bg-secondary3-transparent');
    else
      return('bg-secondary2-transparent');
  }

  const textFromHistory = (bookmakerOdds) => {
    let v = vFromHistory(bookmakerOdds.filter(v => v.name.toLowerCase() === value.toLowerCase())[0]);
    if(v < 0)
      return('text-primary1-base')       
    else if(v > 0)
      return('text-secondary4-base');
    else
      return('text-text1-base');
  }

  return(
    <ValueBox 
      horizontal={horizontal} 
      uri={uri} 
      backgroundColor={bgFromHistory(odds.values)} 
      textColor={textFromHistory(odds.values)} 
      value={v.value.toFixed(2)} 
      previousValue={previousValue(odds.values)}
      label={label} 
      secondary={secondary} 
    />
  );

}

export { OddsBox, ValueBox }