import React, { useEffect, useState } from 'react';

import { ReactComponent as DropDownIcon } from '../images/dropdownicon.svg';

const Select = ({ options, value, onChange, label }) => {

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  }

  const selectedOption = options.filter(o => o.value === value)[0] || { label: 'none' };

  useEffect(() => {
    document.addEventListener('click', () => {
      setDropdownVisible(false);
    });
  }, [dropdownVisible]);

  return(
    <div>
      {label && (
        <div className="mb-1">
          <span className="text-text2-base">{label}</span>
        </div>
      )}
      <div 
        onClick={toggleDropdown} 
        className="text-text2-base relative border-border2-base bg-secondary2-transparent border p-2 rounded flex justify-between items-center cursor-pointer"
      >
        {selectedOption.label}
        <DropDownIcon />
        {dropdownVisible && (
          <div className="absolute top-1 left-1 w-full bg-white border-background2-base border rounded shadow-lg flex flex-col">
            {options.map((o, oi) => {
              return(
                <button 
                  key={oi} 
                  className={`text-left border-background2-base border-b p-2 ${o.value === value ? 'bg-background3-base' : 'white'} hover:bg-background2-base`} 
                  onClick={e => onChange?.(o.value)}
                >
                    {o.label}
                </button>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
};

export { Select }