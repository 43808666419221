import React, { useState, useEffect } from 'react';

import { ReactComponent as FunnelIcon } from '../images/funnel.svg';
import { ReactComponent as ChevronIcon } from '../images/chevron.svg';
import { ReactComponent as EyeIcon } from '../images/eye.svg';
import { SportIcon } from './SportIcon';
import { Spinner } from './Spinner';
import { FilterItem } from './FilterItem';

import { SPORTS, LEAGUES } from '../graphql/queries';

import { BOOKMAKERS } from '../graphql/queries';
import { useQuery, useLazyQuery } from "@apollo/client";

import { camelCase } from '../utils';

const FilterSection = ({ children, icon, label }) => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  return(
    <>
      <div className="rounded-md mb-1 filter-section rounded">
        <div onClick={toggleExpanded} className={`cursor-pointer bg-primary1-base p-2 px-2 text-white flex justify-between items-center ${expanded ? 'rounded-tl rounded-tr' : 'rounded'}`}>
          <div className='flex items-center'>
            {icon}
            <span className="ml-2 uppercase font-bold">{label}</span>
          </div>
          <ChevronIcon width="10" fill="#fff" className={`transform ${expanded ? '-rotate-90' : '-rotate-180'}`} />
        </div>
        {expanded && (
          <div className="bg-white rounded-bl-md rounded-br-md">
            {children}
          </div>
        )}
      </div>
    </>
  )
}

const FilterSubSection = ({ label, children, expanded : defaultExpanded }) => {
  const [expanded, setExpanded] = useState(defaultExpanded || false);
  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  return(
    <div className="filter-sub-section mb-1">
      <div onClick={toggleExpanded} className={`cursor-pointer p-2 px-2 text-primary1-base bg-primary1-transparent flex justify-between items-center ${expanded && 'expanded'}`}>
        {label}
        <ChevronIcon width="10"  fill="#436601" className={`transform ${expanded ? '-rotate-90' : '-rotate-180'}`} />
      </div>
      {expanded && children}
    </div>
  )
}

const FilterBar = ({ 
    live,
    favorites,
    bookmakers,
    sports,
    leagues,
    betType,
    market,
    favoritesChanged,
    bookmakersChanged,
    sportsChanged,
    leaguesChanged,
    availableBookmakers
  }) => {
  
  const { selectedSports, selectedLeagues, selectedBookmakers, favoritesChecked } = {
    selectedSports: sports || [],
    selectedLeagues: leagues || [],
    selectedBookmakers: bookmakers || [],
    favoritesChecked: favorites || false,
  }

  const { loading: loadingBookmakers, data: bookmakersData } = useQuery(BOOKMAKERS, { variables: { first : 200 } });
  const { bookmakers: allBookmakers  } = availableBookmakers || bookmakersData || { bookmakers : { paginatorInfo : {}, data : [] } }
  const { loading : loadingSports, data: sportsData } = useQuery(SPORTS, { variables : { betType, market, live, first: 200 } });  
  const [getLeagues, { loading: loadingLeagues, data: leaguesData }] = useLazyQuery(LEAGUES, { variables : { betType, sports, market, live, first: 500 } });
  
  useEffect(() => {
    if(selectedSports.length > 0) {
      getLeagues();
    }
  }, [selectedSports.length, getLeagues]);

  const [bookmakerFilter, setBookmakerFilter] = useState('');
  const [leaguesFilter, setLeaguesFilter] = useState('');
  
  const bookmakerFilterChanged = (e) => {
    setBookmakerFilter(e.target.value);
  }

  const leaguesFilterChanged = (e) => {
    setLeaguesFilter(e.target.value);
  }

  const toggleFavoritesChecked = () => {
    favoritesChanged?.(!favoritesChecked);
  }

  const toggleSportChecked = (key, checked) => {
    if(!checked) {
      var sportId = sportsData?.sports?.data.find(s => s.name === key)?.id;
      var leaguesToUncheck = (leaguesData?.leagues?.data || []).filter(l => l.sportId === sportId).map(l => l.id);
      leaguesToUncheck.map(leagueId => {
        return leaguesChanged?.(leagueId, false);
      });
    }
    sportsChanged?.(key, checked);
  }

  const toggleLeagueChecked = (key, checked) => {
    leaguesChanged?.(key, checked);
  }

  const toggleBookieChecked = (key, checked) => {
    bookmakersChanged?.(key, checked);
  }  

  const emptyMarkup = (
    <div className='text-text2-base py-1 px-2 my-1'>
      No sports avalable
    </div>
  )

  const loadingLeaguesMarkup = (
    <div className='flex justify-center items-center p-1 border-b border-t border-background1-base py-3'>
      <Spinner width={18} height={12} />
    </div>
  )

  const renderFilteredLeagues = (sport) => {
    const allLeagues = leaguesData.leagues.data;
    const filteredLeagues = allLeagues.filter(l => l.sportId === sport.id).sort((a, b) => {
      return a.name.localeCompare(b.name);
    }).filter(l => {
      return l.name.toLowerCase().includes(leaguesFilter.toLowerCase());
    })
    if(filteredLeagues.length === 0) {
      return(
        <div className='p-2 text-text3-base'>
          No leagues
        </div>
      )
    }
    const selectedLeaguesList = allLeagues.filter(l => selectedLeagues.indexOf(l.id) !== -1)
    const unselectedLeaguesList = allLeagues.filter(l => selectedLeagues.indexOf(l.id) === -1)
    const list = leaguesFilter === '' ? [...selectedLeaguesList, null, ...unselectedLeaguesList] : filteredLeagues;
    return list.map((league, idx) => {
      if(!league && idx === 0) return(<div key={idx}></div>);
      if(!league) return(<div key={idx} className='border-t border-background1-base'></div>);
      return(                
        <FilterItem 
          key={league.id}
          value={league.id}
          onChange={toggleLeagueChecked}
          checkbox={true}
          checked={selectedLeagues.indexOf(league.id) !== -1}
        >
          <div className='whitespace-nowrap overflow-hidden overflow-ellipsis w-full'>{league.name}</div>
        </FilterItem>
      )
    });
  }

  const sportMarkup = loadingSports || sportsData.sports?.data.length === 0 ? emptyMarkup : sportsData.sports?.data.map((sport) => {
    return(
      <div key={sport.name}>
        <FilterItem 
          key={sport.name} 
          value={sport.name}
          onChange={toggleSportChecked}
          checkbox={true}
          checked={selectedSports.indexOf(sport.name) !== -1}
          icon={<SportIcon sportId={sport.name} width="18" height="18" fill="#4E5D78" />}
        >
          <span className="overflow-hidden">{camelCase(sport.name)}</span>
        </FilterItem>
        {loadingLeagues && selectedSports.indexOf(sport.name) !== -1 && loadingLeaguesMarkup}
        {!loadingLeagues && leaguesData?.leagues?.data && selectedSports.indexOf(sport.name) !== -1 && leaguesData.leagues.data.length > 0 && (
          <div className='bg-background3-base border-b border-t border-background1-base'>
            <div className='p-2 py-1 mt-2'>
              <div className='p-1 border border-background1-base border-solid rounded text-primary1-base flex flex-row justify-between'>
                <input 
                  onChange={leaguesFilterChanged}
                  value={leaguesFilter}
                  type="text" 
                  className='w-full'
                  placeholder='Filter leagues'
                />
                {leaguesFilter !== '' && (
                  <button 
                    className='text-text3-base mx-1'
                    onClick={e => setLeaguesFilter('')}
                  >x</button>
                )}
              </div>
            </div>
            <div className='max-h-64 overflow-scroll'>
              {renderFilteredLeagues(sport)}
            </div>
          </div>
        )}
      </div>
    )
  });

  let sportsLabel = `Sports`;
  if(selectedSports.length > 0)
    sportsLabel += ` (${selectedSports.length} / ${loadingSports ? '-' : sportsData.sports?.data.length})`;

  let bookmakerLabel = `Bookmakers `;
  if(selectedBookmakers.length > 0)
    bookmakerLabel += ` (${selectedBookmakers.length} / ${loadingBookmakers ? '-' : allBookmakers.data.length})`;

  const renderFilteredBookmakers = () => {
    
    let filteredBookmakers = allBookmakers.data.filter(b => b.bookmakerId.toLowerCase().indexOf(bookmakerFilter.toLowerCase()) > -1);
    if(filteredBookmakers.length === 0) {
      return(
        <div className='p-2 text-text3-base'>
          No bookmakers
        </div>
      )
    }

    const selectedBookiesList = allBookmakers.data.filter(b => selectedBookmakers.indexOf(b.bookmakerId) !== -1);
    const unselectedBookiesList = allBookmakers.data.filter(b => selectedBookmakers.indexOf(b.bookmakerId) === -1);
    const list = bookmakerFilter === '' ? [...selectedBookiesList, null, ...unselectedBookiesList] : filteredBookmakers;
    return list.map((bookie, idx) => {
      if(!bookie && idx === 0) return(<div key={idx}></div>);
      if(!bookie) return(<div key={idx} className='border-t border-background1-base'></div>);
      return(
        <FilterItem 
          disabled={!bookie}
          key={bookie.bookmakerId}
          value={bookie.bookmakerId}
          checked={selectedBookmakers.indexOf(bookie.bookmakerId) !== -1}
          onChange={toggleBookieChecked}
          checkbox={true}
        >
          <div className="flex justify-between items-center w-full">
            <span className='capitalize'>{bookie.name || bookie.bookmakerId.toLowerCase()}</span>
            {bookie.websiteUrl && (
              <a
                target={'_blank'}
                rel="noreferrer"
                href={bookie.websiteUrl}
              ><EyeIcon fill="#777" /></a>
            )}
          </div>
        </FilterItem>
      )
    })
  }

  return(
    <div className="font-kanit">

      <FilterSection icon={<FunnelIcon width="16" fill="#fff"/>} label="favorites">
        <FilterItem 
          key="favorites_only"
          value={true}
          checked={favoritesChecked}
          checkbox={true}
          onChange={toggleFavoritesChecked}
          >
          Show favorites
        </FilterItem>
      </FilterSection>

      <FilterSection icon={<FunnelIcon width="16" fill="#fff"/>} label="filtering">
        <FilterSubSection label={sportsLabel} expanded={selectedSports.length > 0}>
          {sportMarkup}
        </FilterSubSection>
        <FilterSubSection label={bookmakerLabel} expanded={selectedBookmakers.length > 0}>
          <div className='bg-background3-base'>
            <div className='p-2 py-1 mt-2'>
              <div className='p-1 border border-background1-base border-solid rounded text-primary1-base flex flex-row justify-between'>
                <input 
                  onChange={bookmakerFilterChanged}
                  value={bookmakerFilter}
                  type="text" 
                  className='w-full'
                  placeholder='Filter bookmaker'
                />
                {bookmakerFilter !== '' && (
                  <button 
                    className='text-text3-base mx-1'
                    onClick={e => setBookmakerFilter('')}
                  >x</button>
                )}
              </div>
            </div>
            <div className='max-h-64 overflow-scroll'>
              {renderFilteredBookmakers()}
            </div>
          </div>
        </FilterSubSection>
      </FilterSection>
      
    </div>
  )
}

export { FilterBar }