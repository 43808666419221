import React, { useCallback, useEffect, useState } from 'react';

import Page from "../components/Page";

import { FilterBar } from '../components/FilterBar';
import { SurebetsList } from '../components/lists/SurebetsList';

import { SUREBETS, SUREBETS_BOOKIES } from '../graphql/queries';
import { useQuery } from "@apollo/client";

import { useSearchParams } from "react-router-dom";
import { SearchBar } from '../components/Searchbar';
import { Paginator } from '../components/Paginator';

import { useResult } from '../hooks/useResult';

const SurebetsPage = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const getParams = useCallback((searchParams) => {
    const page = parseInt(searchParams.get('page') || 1);
    const pageSize = parseInt(searchParams.get('pageSize') || 10);
    const orderBy = searchParams.get('orderBy') || 'profit desc';
    const market = searchParams.get('market') || 'h2h';
    const query = searchParams.get('query') || '';
    const sports = (searchParams.get('sports') || '').split(',').filter(v => v);
    const leagues = (searchParams.get('leagues') || '').split(',').filter(v => v);
    const bookmakers = (searchParams.get('bookmakers') || '').split(',').filter(v => v);
    const commenceDay = new Date().toISOString().substring(0, 10);
    const favorites = searchParams.get('favorites') === "true" || false; 
    const live = searchParams.get('live') === "true" || false; 
    const first = pageSize;
    return({
      live, favorites, commenceDay, market, bookmakers, leagues, sports, page, first, orderBy, query
    });
  }, []);

  const [params, setParams] = useState(getParams(searchParams));  
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { loading: isLoading, refetch: refetchEvents, data: eventsResult } = useQuery(SUREBETS, { variables: params });
  const { loading: isLoadingBookmakers, refetch: refetchBookmakers, data: bookmakersResult } = useQuery(SUREBETS_BOOKIES, { variables: params });
  const eventsData = useResult(eventsResult, {});

  useEffect(()  => {
    const timeout = setTimeout(() => {
      setParams(getParams(searchParams));
    }, 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [searchParams, getParams]);

  const paramsChanged = (key, value) => {    
    searchParams.set('page', 1);
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }

  const favoritesChanged = (checked) => {
    paramsChanged('favorites', checked);
  }

  const sportsChanged = (key, checked) => {
    let currentSports = (searchParams.get('sports') || '').split(',');
    if(checked && currentSports.indexOf(key) === -1) {
      currentSports.push(key);
    } else if(!checked && currentSports.indexOf(key) !== -1) {
      currentSports = currentSports.filter(v => v !== key);
    }
    paramsChanged('sports', currentSports.filter(v => v));
  }

  const onLeagueSelected = (league) => {
    sportsChanged(league.sport.name, true);
    leaguesChanged(league.id, true);
  }

  const leaguesChanged = (key, checked) => {
    let currentLeagues = (searchParams.get('leagues') || '').split(',');
    if(checked && currentLeagues.indexOf(key) === -1) {
      currentLeagues.push(key);
    } else if(!checked && currentLeagues.indexOf(key) !== -1) {
      currentLeagues = currentLeagues.filter(v => v !== key);
    }
    paramsChanged('leagues', currentLeagues.filter(v => v));
  }

  const bookmakersChanged = (key, checked) => {
    let currentBookmakers = (searchParams.get('bookmakers') || '').split(',');
    if(checked && currentBookmakers.indexOf(key) === -1) {
      currentBookmakers.push(key);
    } else if(!checked && currentBookmakers.indexOf(key) !== -1) {
      currentBookmakers = currentBookmakers.filter(v => v !== key);
    }
    paramsChanged('bookmakers', currentBookmakers.filter(v => v));
  }

  const marketChanged = (market) => {
    paramsChanged('leagues', []);
    paramsChanged('sports', []);
    paramsChanged('market', market);
  }

  const queryChanged = (value) => {
    paramsChanged('query', value);
  }

  const orderByChanged = (orderBy) => {
    paramsChanged('orderBy', orderBy);
  }
  
  const gotoPage = (page) => {
    setSearchParams('page', page);
  }

  const refresh = async () => {
    setIsRefreshing(true);
    await refetchEvents();
    setIsRefreshing(false);
  }

  const setPrematchOnly = () => {
    paramsChanged('live', false);
  }

  const setLiveOnly = () => {
    paramsChanged('live', true);
  }
  
  const isPrematchOnly = () => {
    return !params.live;
  }

  const isLiveOnly = () => {
    return params.live;
  }

  /** tabs and sort options */
  
  const tabs = [
    { label: 'Prematch', onClick: setPrematchOnly, active: isPrematchOnly() },
    { label: 'Live', onClick: setLiveOnly, active: isLiveOnly() },
  ]
  
  const sortOptions = [
    { value: 'profit desc', label: 'Profit' },
    { value: 'date asc', label: 'Date' }
  ]

  const filterBar = (
    <FilterBar 
      live={isLiveOnly()}
      favorites={params.favorites}
      sports={params.sports}
      leagues={params.leagues}
      betType='surebet'
      market={params.market}
      bookmakers={params.bookmakers}
      availableBookmakers={{ bookmakers: { data:( bookmakersResult?.surebetsBookmakers || []).map(b => ({ bookmakerId: b, name: b })) } }}
      favoritesChanged={favoritesChanged}
      sportsChanged={sportsChanged}
      leaguesChanged={leaguesChanged}
      bookmakersChanged={bookmakersChanged}
    />
  );

  const searchBar = (
    <SearchBar 
      tabs={tabs}
      query={params.query}
      queryChanged={queryChanged}
      market={params.market}
      marketChanged={marketChanged}
      orderBy={params.orderBy}
      orderByChanged={orderByChanged}
      sortOptions={sortOptions}
    />
  );

  const paginator = (
    <Paginator
      total={eventsData.surebets?.paginatorInfo?.total || 0} 
      page={params.page}
      pageSize={params.pageSize} 
      onGotoPage={gotoPage}
      onRefresh={refresh}
      loading={isLoading || isRefreshing}
    />
  );

  return(
    <Page
      filterBar={filterBar}
      searchBar={searchBar}
    >      
      <SurebetsList
        loading={isLoading} 
        events={eventsData.surebets?.data || []} 
        marketId={params.market}
        paginator={paginator} 
        onLeagueSelected={onLeagueSelected}
      />
    </Page>
  );

}

export default SurebetsPage;