import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const MenuLink = ({ to, children }) => {
  const location = useLocation();
  const active = location.pathname === to;
  const className = `text-white p-3 px-5 uppercase ${active && 'bg-primary2-base'} hover:bg-primary2-base`;
  return(
    <Link className={className} to={to} >
      {children}
    </Link>
  )
}

function MainMenu(props) {
  return (
    <div className="flex">
      <MenuLink to="/">
        Start
      </MenuLink>
      <MenuLink to="/learn">
        How does it work
      </MenuLink>
      <MenuLink to="/about">
        About us
      </MenuLink>
    </div>
  );
}

export default MainMenu;