import { OddsBox } from "../OddsBox";

function BestHead2HeadOdds({ odds }) {  
  if(!odds) return null;
  const home = odds.find(o => o.name === 'home');
  const draw = odds.find(o => o.name === 'draw');
  const away = odds.find(o => o.name === 'away');
  return(
    <div className="grid grid-cols-3 gap-2">
      <div><OddsBox odds={home ? { values: [{ name: 'home', value: home.value, history: home?.history }] } : null} value='home' secondary={home?.oddsEntry?.bookmakerId} uri={home?.oddsEntry?.bookmakerURI } /></div>
      <div><OddsBox odds={draw ? { values: [{ name: 'draw', value: draw.value, history: draw?.history }] } : null} value='draw' secondary={draw?.oddsEntry?.bookmakerId} uri={draw?.oddsEntry?.bookmakerURI } /></div>
      <div><OddsBox odds={away ? { values: [{ name: 'away', value: away.value, history: away?.history }] } : null} value='away' secondary={away?.oddsEntry?.bookmakerId} uri={away?.oddsEntry?.bookmakerURI } /></div>
    </div>
  );
}

function SurebetHead2Head({ surebet }) {  
  const home = surebet.values.find(b => b.name === 'home');
  const away = surebet.values.find(b => b.name === 'away');
  const draw = surebet.values.find(b => b.name === 'draw');  
  return(
    <div className="grid grid-cols-3 gap-2">
      <div><OddsBox odds={surebet} value='home' secondary={home.bookmakerId} uri={home.bookmakerURI } /></div>
      <div>
        {draw && (<OddsBox odds={surebet} value='away' secondary={away.bookmakerId} uri={away.bookmakerURI } />)}
      </div>
      <div><OddsBox odds={surebet} value='draw' secondary={draw.bookmakerId} uri={draw.bookmakerURI } /></div>
    </div>
  );
}

export { BestHead2HeadOdds, SurebetHead2Head };