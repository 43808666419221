import { useEffect, useReducer } from "react";
import { HashRouter as Router, Routes, Route, Link } from "react-router-dom"
import { AuthContext, defaultAuthState } from './AuthContext';
import { SettingsContext, defaultSettingsState } from './SettingsContext';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

/** components */
import MainMenu from './components/MainMenu';
import { SigninModal } from './components/modals/Signin';
import { SignupModal } from './components/modals/Signup';
import { ConfirmUserEmailModal } from './components/modals/ConfirmUserEmail';

import StartPage from './pages/Start';
import AccountPage from './pages/Account';

import HomePage from './pages/Home';
import SurebetsPage from './pages/Surebets';
import ValueBetsPage from './pages/Valuebets';

import BestOddsPage from './pages/BestOdds';
import LivePage from './pages/Live';
import AboutPage from './pages/About';
import LearnPage from './pages/Learn';
import ReviewsPage from './pages/Reviews';
import LeavePage from './pages/Leave';
import TermsPage from './pages/Terms';
import PrivacyPage from './pages/Privacy';

import MatchDetails from './pages/MatchDetails';

import { UserMenu } from "./components/UserMenu";
import { CREATE_USER, GET_ME } from "./graphql/queries";

import { store_favorites_from_query } from './components/FavoriteIcon';

/** icons */
import { ReactComponent as Logo } from './images/logo2.svg';
import { ReactComponent as EighteenPlusIcon } from './images/18plus.svg';

import { gql } from "@apollo/client";

Amplify.configure(awsconfig);

const settingsReducer = (state, action) => {
  switch (action.type) {
    case "setBetAmount":
      return ({ ...state, betAmount: action.value });
    case "setBetCurrency":
      return ({ ...state, betCurrency: action.value });
    default:
      return (state)
  }
}

const authReducer = (state, action) => {
  switch (action.type) {
    case "showLoginModal":
      return ({ ...state, showLoginModal: true, showSignupModal: false, showConfirmationModal: false });
    case "showSignupModal":
      return ({ ...state, showSignupModal: true, showLoginModal: false, showConfirmationModal: false });
    case "showConfirmationModal":
      return ({ ...state, showSignupModal: false, showLoginModal: false, showConfirmationModal: true, confirmationEmail: action.email, confirmationPassword: action.password });
    case "hideLoginModal":
      return ({ ...state, showLoginModal: false });
    case "hideSignupModal":
      return ({ ...state, showSignupModal: false });
    case "hideConfirmationModal":
      return ({ ...state, showConfirmationModal: false, confirmationEmail: undefined, confirmationPassword: undefined });
    case "setIsAuthenticated":
      if (action.value === true)
        return ({ ...state, isAuthenticated: action.value, showSignupModal: false, showLoginModal: false });
      else
        return ({ ...state, isAuthenticated: action.value, });
    default:
      return (state)
  }
}

function App({ client }) {

  const [authState, dispatchAuthState] = useReducer(authReducer, defaultAuthState);
  const [settingsState, dispatchSettingsState] = useReducer(settingsReducer, defaultSettingsState);

  let cache = window.apolloCache;

  useEffect(() => {
    const ws = new WebSocket("wss://26dqqg4k0a.execute-api.eu-north-1.amazonaws.com/dev");
    ws.onopen = (event) => {
      console.log('websocket connected');
    };
    ws.onmessage = function (event) {
      console.log('websocket message', JSON.parse(event.data));
      const match = JSON.parse(event.data);
      cache.modify({
        id: cache.identify({
          __typename: 'Event',
          id: match.id
        }),
        fields: {
          updatedAt() {
            return match.updatedAt;
          }
        }
      });
      for (const ce of match.commenceEntries) {
        for (const odds of ce.odds) {
          for (const ov of odds.values) {
            for (const t of ['OddsValue', 'BestOddsValue']) {
              let myObject = {
                __typename: t,
                id: ov.id
              }
              const current = client.readFragment({
                id: `${t}:${ov.id}`,
                fragment: gql`
                  fragment MyOddsValue on OddsValue {
                    value
                  }
                `
              });
              if (!current) continue;
              cache.modify({
                id: cache.identify(myObject),
                fields: {
                  value(cachedValue) {
                    return ov.value;
                  },
                  history(cachedValue) {
                    return [
                      {
                      __typename: "OddsValueHistory",
                      value: current.value
                      },
                      ...cachedValue];
                  }
                }
              });
            }
          }
        }
      }
    };
    ws.onclose = (event) => {
      console.log('websocket closed');
    }
    return (() => {
      console.log('websocket closing')
      ws.close();
    })
  }, [cache, client]);

  useEffect(() => {
    (async () => {
      try {
        let currentUser = await Auth.currentAuthenticatedUser();
        console.log('currentAuthenticatedUser - user', currentUser);
        let token = (await Auth.currentSession()).getIdToken().getJwtToken();
        sessionStorage.setItem('token', token);
        dispatchAuthState({ type: 'setIsAuthenticated', value: true });
        client.query({
          query: GET_ME
        })
          .then(r => r.data.me)
          .then(async user => {
            if (!user) {
              let userCreateResponse = await client.mutate({
                mutation: CREATE_USER,
                variables: { username: currentUser.username }
              });
              user = userCreateResponse.data?.createUser;
            }
            store_favorites_from_query(user.favorites);
          });
      } catch (error) {
        console.log('currentAuthenticatedUser - error', error)
        dispatchAuthState({ type: 'setIsAuthenticated', value: false });
      };
    })();
  }, [client]);

  const signIn = () => {
    dispatchAuthState({ type: 'showLoginModal' })
  }
  
  const signUp = () => {
    dispatchAuthState({ type: 'showSignupModal' })
  }

  const signOut = () => {
    Auth.signOut();
    sessionStorage.clear();
    dispatchAuthState({ type : 'setIsAuthenticated', value : false })
  }

  return (
    <AuthContext.Provider value={{ state: authState, dispatch: dispatchAuthState }}>
      <SettingsContext.Provider value={{ state: settingsState, dispatch: dispatchSettingsState }}>
        <Router>
          <div className="flex justify-center flex-row h-full flex-grow">
            <div className="items-center flex flex-col w-full h-full">              
              {/* main menu */}
              <div className="w-full bg-primary1-base flex justify-center">
                <div className="content grid grid-cols-5">
                  <div className="flex justify-start items-center my-4">
                    <Link to="/">
                      <Logo fill="#fff" height="32" className="transform" />
                    </Link>
                  </div>
                  <div className="col-span-3 flex justify-start items-center">
                    <MainMenu />
                  </div>
                  {!authState.isAuthenticated && (
                    <div className="flex justify-end items-center">
                      <button onClick={signUp} className="p-2 px-5 bg-primary2-base border-2 text-white uppercase smaller border-primary2-base rounded-full">Signup</button>
                      <button onClick={signIn} className="p-2 px-5 bg-primary1-base border-2 text-white uppercase smaller border-white rounded-full ml-2">Login</button>
                    </div>
                  )}
                  {authState.isAuthenticated && (
                    <UserMenu />
                  )}
                </div>
              </div>
              <div className="flex flex-grow">
                {/* sign in modal */}
                {authState.showLoginModal && (
                  <SigninModal />
                )}
                {authState.showSignupModal && (
                  <SignupModal />
                )}
                {authState.showConfirmationModal && (
                  <ConfirmUserEmailModal />
                )}
                {/* main content */}
                <Routes>
                  {!authState.isAuthenticated && (
                    <Route path="/" element={<StartPage onSignUp={signUp} />} />
                  )}
                  <Route path="/learn" element={<LearnPage />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/reviews" element={<ReviewsPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  {authState.isAuthenticated && (
                    <>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/account" element={<AccountPage />} />
                      <Route path="/surebets" element={<SurebetsPage />} />
                      <Route path="/surebets/:id" element={<MatchDetails oddsType={'surebets'} />} />
                      <Route path="/valuebets" element={<ValueBetsPage />} />
                      <Route path="/valuebets/:id" element={<MatchDetails oddsType={'valuebets'}/>} />
                      <Route path="/odds" element={<BestOddsPage />} />
                      <Route path="/odds/:id" element={<MatchDetails oddsType={'odds'}/>} />
                      <Route path="/live" element={<LivePage />} />
                      <Route path="/live/:id" element={<MatchDetails oddsType={'live'} />} />
                      <Route path="/leave/:uri" element={<LeavePage />} />
                    </>
                  )}
                </Routes>
              </div>
              <div className="items-center flex flex-col w-full bg-primary1-base footer">
                <div className="content h-full py-14 flex justify-between">
                  <div className="flex items-center justify-start flex-col h-full text-white">
                    <Logo fill="#fff" className="transform" />
                    <p className="text-xs my-2">© 2023 Betfavor - All Rights Reserved.</p>
                    <div className="text-2xl flex justify-between items-center w-full my-2">
                      <EighteenPlusIcon /> <a rel="noreferrer" href="https://begambleware.org" target="_blank">BeGambleAware</a>
                    </div>
                  </div>
                  <div className="flex items-start justify-start flex-col h-full text-white">
                    <Link to="/learn" className="mb-2">HOW DOES IT WORK</Link>
                    <Link to="/about" className="mb-2">ABOUT US</Link>
                    <Link to="/privacy" className="mb-2">PRIVACY POLICY</Link>
                    <Link to="/terms" className="mb-2">TERMS & CONDITIONS</Link>
                  </div>
                  <div className="flex items-start justify-start flex-col h-full text-white">
                    <Link to="/surebets" className="mb-2">SUREBETS</Link>
                    <Link to="/valuebets" className="mb-2">VALUEBETS</Link>
                    <Link to="/odds" className="mb-2">BEST ODDS</Link>
                    <Link to="/live" className="mb-2">LIVE</Link>
                  </div>
                  <div className="items-start justify-start flex-col h-full text-white">
                    <h6 className="mb-2">FEEDBACK</h6>
                    <p className="mb-2">
                      Lorem ipsum dolor sit amet, <br />consect etur adipiscing elit aliquam<br /> imperdiet, odio nec elementum.
                    </p>
                    <p>
                      <a href="mailto:feedback@betfavor.com">Help us get better</a>
                    </p>
                  </div>
                  <div className="flex items-center justify-start flex-col h-full">
                    {!authState.isAuthenticated && (
                      <>
                        <button onClick={signUp} className="p-2 px-5 bg-primary2-base border-2 text-white uppercase smaller border-primary2-base rounded-full w-full">Signup</button>
                        <button onClick={signIn} className="p-2 px-5 bg-primary1-base border-2 text-white uppercase smaller border-white rounded-full mt-4 w-full">Login</button>
                      </>
                    )}
                    {authState.isAuthenticated && (
                      <button onClick={signOut} className="p-2 px-5 bg-primary1-base border-2 text-white uppercase smaller border-white rounded-full mt-4 w-full">Logout</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      </SettingsContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
