import React, { useCallback, useEffect, useState } from 'react';

import Page from "../components/Page";

import { FilterBar } from '../components/FilterBar';
import { ValuebetsList } from '../components/lists/ValuebetsList';

import { VALUEBETS, VALUEBETS_BOOKIES } from '../graphql/queries';
import { useQuery } from "@apollo/client";

import { useSearchParams } from "react-router-dom";
import { SearchBar } from '../components/Searchbar';
import { Paginator } from '../components/Paginator';

import { useResult } from '../hooks/useResult';

const ValueBetsPage = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const getParams = useCallback((searchParams) => {
    const commenceTime = parseInt(new Date().valueOf() / 1000);
    const page = parseInt(searchParams.get('page') || 1);
    const pageSize = parseInt(searchParams.get('pageSize') || 10);
    const betType = searchParams.get('betType') || 'valuebet';
    const orderBy = searchParams.get('orderBy') || 'overvalue';
    const market = searchParams.get('market') || 'h2h';
    const query = searchParams.get('query') || '';
    const sports = (searchParams.get('sports') || '').split(',').filter(v => v);
    const leagues = (searchParams.get('leagues') || '').split(',').filter(v => v);
    const bookmakers = (searchParams.get('bookmakers') || '').split(',').filter(v => v);
    const commenceDay = new Date().toISOString().substring(0, 10);
    const favorites = searchParams.get('favorites') === "true" || false; 
    const live = searchParams.get('live') === "true" || false; 
    const first = pageSize;
    return({
      live, commenceTime, first, page, pageSize, betType, sports, leagues, bookmakers, market, orderBy, query, commenceDay, favorites
    });
  }, []);

  let [params, setParams] = useState(getParams(searchParams));
  const { loading: isLoading, refetch: refetchEvents, data: eventsResult } = useQuery(VALUEBETS, { variables : params });
  const { loading: isLoadingBookmakers, refetch: refetchBookmakers, data: bookmakersResult } = useQuery(VALUEBETS_BOOKIES, { variables: params });
  const eventsData = useResult(eventsResult, {});

  console.log('bookmakersResult', bookmakersResult);

  useEffect(()  => {
    const timeout = setTimeout(() => {
      setParams(getParams(searchParams));
    }, 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [searchParams, getParams]);

  const paramsChanged = () => {    
    searchParams.set('page', 1);
    setSearchParams(searchParams)
  }

  const favoritesChanged = (checked) => {
    searchParams.set('favorites', checked);
    paramsChanged(searchParams);
  }

  const sportsChanged = (key, checked) => {
    let currentSports = (searchParams.get('sports') || '').split(',');
    if(checked && currentSports.indexOf(key) === -1) {
      currentSports.push(key);
    } else if(!checked && currentSports.indexOf(key) !== -1) {
      currentSports = currentSports.filter(v => v !== key);
    }
    searchParams.set('sports', currentSports.filter(v => v));
    paramsChanged(searchParams);
  }

  const leaguesChanged = (key, checked) => {
    let currentLeagues = (searchParams.get('leagues') || '').split(',');
    if(checked && currentLeagues.indexOf(key) === -1) {
      currentLeagues.push(key);
    } else if(!checked && currentLeagues.indexOf(key) !== -1) {
      currentLeagues = currentLeagues.filter(v => v !== key);
    }
    searchParams.set('leagues', currentLeagues.filter(v => v));
    paramsChanged(searchParams);
  }

  const bookmakersChanged = (key, checked) => {
    let currentBookmakers = (searchParams.get('bookmakers') || '').split(',');
    if(checked && currentBookmakers.indexOf(key) === -1) {
      currentBookmakers.push(key);
    } else if(!checked && currentBookmakers.indexOf(key) !== -1) {
      currentBookmakers = currentBookmakers.filter(v => v !== key);
    }
    searchParams.set('bookmakers', currentBookmakers.filter(v => v));
    paramsChanged(searchParams);
  }

  const marketChanged = (market) => {
    searchParams.set('leagues', []);
    searchParams.set('sports', []);
    searchParams.set('market', market);
    paramsChanged(searchParams);
  }

  const queryChanged = (value) => {
    searchParams.set('query', value);
    paramsChanged(searchParams);
  }

  const orderByChanged = (orderBy) => {
    searchParams.set('orderBy', orderBy);
    paramsChanged(searchParams);
  }
  
  const gotoPage = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  const refresh = () => {
    refetchEvents();
  }

  const sortOptions = [
    { value: 'overvalue desc', label: 'Overvalue' },
    { value: 'date asc', label: 'Date' }
  ]

  const setPrematchOnly = () => {
    searchParams.set('live', false);
    setSearchParams(searchParams);
  }

  const setLiveOnly = () => {
    searchParams.set('live', true);
    setSearchParams(searchParams);
  }
  
  const isPrematchOnly = () => {
    return !params.live;
  }

  const isLiveOnly = () => {
    return params.live;
  }

  const tabs = [
    { label: 'Prematch', onClick: setPrematchOnly, active: isPrematchOnly() },
    { label: 'Live', onClick: setLiveOnly, active: isLiveOnly() },
  ]

  const filterBar = (
    <FilterBar 
      live={isLiveOnly()}
      favorites={params.favorites}
      sports={params.sports}
      leagues={params.leagues}
      betType='valuebet'
      market={params.market}
      bookmakers={params.bookmakers}
      availableBookmakers={{ bookmakers: { data:( bookmakersResult?.valuebetsBookmakers || []).map(b => ({ bookmakerId: b, name: b })) } }}
      favoritesChanged={favoritesChanged}
      sportsChanged={sportsChanged}
      leaguesChanged={leaguesChanged}
      bookmakersChanged={bookmakersChanged}
    />
  );

  const searchBar = (
    <SearchBar 
      tabs={tabs}
      query={params.query}
      queryChanged={queryChanged}
      market={params.market}
      marketChanged={marketChanged}
      orderBy={params.orderBy}
      orderByChanged={orderByChanged}
      sortOptions={sortOptions}
    />
  );

  const paginator = (
    <Paginator
      total={eventsData.valuebets?.paginatorInfo?.total || 0} 
      page={params.page}
      pageSize={params.pageSize} 
      onGotoPage={gotoPage}
      onRefresh={refresh}
    />
  );

  return(
    <Page
      filterBar={filterBar}
      searchBar={searchBar}
    >   
      <ValuebetsList
        loading={isLoading} 
        events={eventsData.valuebets?.data || []} 
        marketId={params.market}
        paginator={paginator} 
      />
    </Page>
  );

}

export default ValueBetsPage;