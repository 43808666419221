import React from 'react';

import { ValuebetRow } from '../matchrows/ValuebetRow';
import { Spinner } from '../Spinner';

const ValuebetsList = ({ 
  paginator, 
  events, 
  loading,
  marketId,
  onLeagueSelected
}) => {

  const loadingMarkup = (
    <div className='flex justify-center items-center p-12'>
      <Spinner />
    </div>
  )

  const emptyMarkup = (
    <div className='flex justify-center items-center p-12'>
      No entries found
    </div>
  );
  
  return (
    <>
      <div className='mb-2'>
        {paginator}
      </div>
      <div className='border border-background2-base rounded-md'>
        {marketId === 'h2h' && (
          <div className="grid grid-cols-6 p-2 text-text2-base border-b border-background2-base">
            <div className="col-span-5">
              Match
            </div>
            <div className='text-center'>
              Bet
            </div>
          </div>
        )}
        {marketId === 'overunder' && (
          <div className="grid grid-cols-5 p-2 text-text2-base border-b border-background2-base">
            <div className="col-span-4">
              Match
            </div>
            <div className='text-center'>
              Bet
            </div>
          </div>
        )}
        {marketId === 'asianhcp' && (
          <div className="grid grid-cols-5 p-2 text-text2-base border-b border-background2-base">
            <div className="col-span-4">
              Match
            </div>
            <div className='text-center'>
              Bet
            </div>
          </div>
        )}
        {loading && loadingMarkup}
        {!loading && events.length === 0 && emptyMarkup}
        {!loading && events.length > 0 && (
          <div>
            {events.map((event) => {
              return (event.commenceEntries.map((commenceEntry, cix) => {
                if(commenceEntry.best_valuebets.filter(sb => sb.marketId === marketId).length > 0) {
                  const firstValuebet = commenceEntry.best_valuebets[0];
                  return (
                    <ValuebetRow 
                      key={`${commenceEntry.id}`} 
                      entry={commenceEntry} 
                      bets={firstValuebet} 
                      match={event} 
                      market={marketId}
                      onLeagueSelected={onLeagueSelected}
                    />
                  )
                }
                return(null);
              }))
            })}
          </div>
        )}
      </div>
    </>
  )

}

export { ValuebetsList }