import React, { useState, useEffect } from 'react';

import { ReactComponent as CheckIcon } from '../images/checkbox.svg';
import { ReactComponent as CheckedIcon } from '../images/checkbox-checked.svg';

const FilterItem = ({value, checkbox, checked : _checked, icon, disabled, children, onChange, className}) => {  
  
  const [checked, setChecked] = useState(_checked);
  const toggleChecked = () => { const val = !checked; setChecked(val); if(typeof(onChange) == 'function') onChange(value, val); }

  useEffect(() => {
    setChecked(_checked);
  }, [_checked]);

  return(
    <div 
      onClick={e => disabled ? undefined : toggleChecked()} 
      className={`${disabled !== true && 'cursor-pointer'} ${className} p-2 px-2 text-sm flex justify-between items-center font-light font-roboto ${disabled ? 'text-text4-base' : 'text-text1-base'} hover:bg-background3-base`}
    >
      <div className={`flex justify-start items-center w-full`}>
        {disabled && (
          <>
            <div>
              <CheckIcon className='mr-2' stroke="#eee" fill="#ccc"/>
            </div>
            {children}
          </>
        )}
        {disabled !== true && (
          <>
            <div>
              {!checked && checkbox && (<CheckIcon className='mr-2' stroke='#5B8C00'/>)}
              {checked && checkbox && (<CheckedIcon className='mr-2'/>)}
            </div>
            {children}
          </>
        )}
      </div>
      <div>{icon}</div>
    </div>
  )
}

export { FilterItem }
