import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as FootballIcon } from '../images/sporticons/american_football.svg';
import { ReactComponent as CalendarIcon } from '../images/calendar.svg';
import { ReactComponent as ClockIcon } from '../images/clock.svg';
import { ReactComponent as DiagramIcon } from '../images/diagram.svg';
import { ReactComponent as CheckIcon } from '../images/check.svg';
import { ReactComponent as ChevronIcon } from '../images/chevron.svg';
import { ReactComponent as CheckedIcon } from '../images/checked.svg';
import Page from '../components/Page';

function StartPage({ onSignUp }) {
  return ( 
    <Page showTabs={false} backgroundColor={'transparent'} showAds={false}>
      {/* */}
      <div className='bg-white p-4 rounded-l mb-8'>
        <div className="w-full hero h-96 flex relative">
          <div className="w-1/2 sporty-gradient h-full hero-clip">

          </div>
          <div className="w-1/2 absolute top-0 left-0 flex justify-center items-center h-full">
            <div className="w-3/4 flex justify-start items-center h-full">
              <div className="w-full">
                <h1 className="hero-title text-white">The best free surebet service</h1>
                <div className="mt-4">
                  <Link to="/about" className="mr-4  p-3 px-5 border text-white uppercase smaller border-white rounded-full">Learn more</Link>
                  <button onClick={onSignUp} className="p-3 px-5 bg-primary2-base border text-white uppercase smaller border-primary2-base rounded-full">Sign up now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* */}
      <div className='bg-white p-4 rounded-l mb-8 rounded-md'>
        <div className="w-full bg-background4-base flex justify-center p-12">
          <div className="grid grid-cols-4 gap-12">
            <div>
              <h5 className="text-primary2-base">Arbitrage betting</h5>
              <p className="text-text2-base medium my-4">Bet and cover all possible outcomes of a game and win no matter what</p>
              <ul className="text-text2-base mb-6">
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Win on every bet</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Practically no risk</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Make a monthly profit</li>
              </ul>
              <button className='uppercase bg-primary1-base text-white font-bold rounded-full p-2 px-6'>
                Learn more
              </button>
            </div>
            <div>
              <h5 className="text-primary2-base">Value betting</h5>
              <p className="text-text2-base medium my-4">The easiest way of making money from sportsbetting</p>
              <ul className="text-text2-base mb-6">
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Win on every bet</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Practically no risk</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Make a monthly profit</li>
              </ul>
              <button className='uppercase bg-primary1-base text-white font-bold rounded-full p-2 px-6'>
                Learn more
              </button>
            </div>
            <div>
              <h5 className="text-primary2-base">Middle betting</h5>
              <p className="text-text2-base medium my-4">Hit the middle when betting on different over / under odds</p>
              <ul className="text-text2-base mb-6">
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Win on every bet</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Practically no risk</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Make a monthly profit</li>
              </ul>
              <button className='uppercase bg-primary1-base text-white font-bold rounded-full p-2 px-6'>
                Learn more
              </button>
            </div>
            <div>
              <h5 className="text-primary2-base">Odds comparison</h5>
              <p className="text-text2-base medium my-4">Lorem ipsum dolor sit amet consectetur adipiscing elit</p>
              <ul className="text-text2-base mb-6">
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Win on every bet</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Practically no risk</li>
                <li className='flex justify-start items-center'><CheckedIcon className='mr-2' />Make a monthly profit</li>
              </ul>
              <button className='uppercase bg-primary1-base text-white font-bold rounded-full p-2 px-6'>
                Learn more
              </button>
            </div>
          </div>        
        </div>
      </div>
      {/* */}
      <div className="w-full flex justify-center p-4">
        <div className='flex flex-row w-full'>
          <div className='flex flex-grow flex-col pr-12'>
            <h3 className='text-primary2-base'>Latest surebets</h3>
            <div className='rounded-tl-md rounded-tr-md bg-primary1-base mt-4 px-3 flex justify-between text-white p-1'>
              <div className='flex items-center'>
                <FootballIcon stroke="#fff" fill="#fff" width="18" className="mr-2" />
                <h6>Atlanta Falcons - Philadelphia Eagles</h6>
              </div>
              <div className='flex items-center'>
                <CalendarIcon stroke="#fff" fill="#fff" width="23" className="mr-1" />
                2021-02-12
                <ClockIcon stroke="#fff" fill="#fff" width="23" className="ml-2 mr-1" />
                18:22
              </div>
            </div>
            <div className='bg-background3-base p-4 rounded-bl-md rounded-br-md'>
              <div className='border border-background2-base grid grid-cols-10 bg-white border-b border-background2-base rounded-tl-md rounded-tr-md'>
                <div className='col-span-3 text-center text-text3-base p-1'>1</div>
                <div className='col-span-3 text-center text-text3-base p-1'>X</div>
                <div className='col-span-3 text-center text-text3-base p-1'>2</div>
                <div className='text-center flex items-center justify-center'><DiagramIcon /></div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary1-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-primary1-base'>3.4</span> Betvictor
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-background3-base p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold'>3.4</span> Piwi
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary3-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-secondary4-base'>3.4</span> Sky bet
                  </div>
                </div>
                <div className='p-2'>
                  <div className='bg-primary1-base p-1 text-center text-white rounded-md'>
                    <span>0.98%</span>
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary1-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-primary1-base'>3.4</span> Betvictor
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-background3-base p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold'>3.4</span> Piwi
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary3-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-secondary4-base'>3.4</span> Sky bet
                  </div>
                </div>
                <div className='p-2'>
                  <div className='bg-primary1-base p-1 text-center text-white rounded-md'>
                    <span>0.98%</span>
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary1-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-primary1-base'>3.4</span> Betvictor
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-background3-base p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold'>3.4</span> Piwi
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary3-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-secondary4-base'>3.4</span> Sky bet
                  </div>
                </div>
                <div className='p-2'>
                  <div className='bg-primary1-base p-1 text-center text-white rounded-md'>
                    <span>0.98%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-between p-4 px-5'>
              <div className='flex justify-center items-center text-primary1-base'>
                Show all 50 surebets 
                <CheckIcon className='ml-2'/>
              </div>
              <div className='flex'>
                <button className='bg-primary1-base rounded-md w-7 h-7 flex items-center justify-center mr-1'>
                  <ChevronIcon stroke='#fff' fill='#fff' width="12" height="14"/>
                </button>
                <button className='bg-primary1-base rounded-md w-7 h-7 flex items-center justify-center'>
                  <ChevronIcon className='transform rotate-180' stroke='#fff' fill='#fff' width="12" height="14"/>
                </button>
              </div>
            </div>
            <div className='p-4 bg-white rounded-md'>
              <div className='bg-background1-base h-32 w-full'>
              </div>
            </div>
          </div>
          <div className='p-4 bg-white rounded-md'>
            <div className="banner-300">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </Page>
   );
}

export default StartPage;