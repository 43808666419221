import { OddsBox } from "../OddsBox";

import { ReactComponent as ChevronIcon } from '../../images/chevron.svg';
import { useState } from "react";

function BestAsianHandicapOdds({ odds }) {  
  const [betIndex, setBetIndex] = useState(0);

  if(!odds) return null;

  var names = [];
  for(const o of odds) {
    var aName = o.name.replace('home#', '').replace('away#', '');
    if(names.indexOf(aName) === -1) {
      names.push(aName);
    }
  }

  names.sort((a, b) => {
    return a.localeCompare(b);
  });

  const gotoBetIndex = (index) => {
    if(index < 0)
      index = 0;
    if(index > names.length - 1)
      index = names.length - 1;
    setBetIndex(index);
  }

  return(
    <div>
      {names.filter((n, i) => i === betIndex).map((name, index) => {
        
        var aName = `home#${name}`;
        var bName = `away#${name}`;

        const a = odds.find(o => o.name === aName);
        const b = odds.find(o => o.name === bName);
        
        return(
          <div className="grid grid-cols-2" key={index}>
            <div className="flex justify-center items-center">
              {names.length > 0 && (
                <div className="py-2 h-full mr-2">
                  <button 
                    onClick={e => gotoBetIndex(betIndex - 1)}
                    className="border border-border2-base bg-background3-base hover:bg-background2-base rounded h-full px-1"
                  >
                    <ChevronIcon stroke="black" fill="black" width="14" height="15"/>
                  </button>
                </div>
              )}
              <div className="w-full">
                <OddsBox odds={a ? { values: [{ name: 'home', value: a.value, history: a.history }] } : null} value='home' label={aName.replace('home#', '')} secondary={a?.bookmakerId} uri={a?.bookmakerURI } />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="w-full">
                <OddsBox odds={b ? { values: [{ name: 'home', value: b.value, history: b.history }] } : null} value='home' label={bName.replace('away#', '')}  secondary={b?.bookmakerId} uri={b?.bookmakerURI } />
              </div>
              {names.length > 0 && (
                <div className="py-2 h-full mr-2 ml-2">
                  <button 
                    onClick={e => gotoBetIndex(betIndex + 1)} 
                    className="border border-border2-base bg-background3-base hover:bg-background2-base rounded h-full px-1"
                  >
                    <ChevronIcon stroke="black" fill="black" width="14" height="15" className="transform rotate-180"/>
                  </button>
                </div>
              )}
            </div>            
          </div>
        );

      })}
    </div>
  )    
}

function AsianHandicap({ odds }) {

  const [betIndex, setBetIndex] = useState(0);

  var names = [];
  for(const o of odds) {
    for(const v of o.values) {
      var aName = v.name.replace('home#', '').replace('away#', '');
      if(names.indexOf(aName) === -1) {
        names.push(aName);
      }
    }
  }

  names.sort((a, b) => {
    return a.localeCompare(b);
  });

  const gotoBetIndex = (index) => {
    if(index < 0)
      index = 0;
    if(index > names.length - 1)
      index = names.length - 1;
    setBetIndex(index);
  }

  const sortBy = (name, a, b) => {
    const aVal = a.values.find(v => v.name === name);
    const bVal = b.values.find(v => v.name === name);
    return(bVal.value - aVal.value);
  }

  return(
    <div>
      {names.filter((n, i) => i === betIndex).map((name, index) => {
        
        var aName = `home#${name}`;
        var bName = `away#${name}`;

        let aSorted = odds.filter(o => o.values.filter(v => v.name === aName).length > 0).sort((a, b) => sortBy(aName, a, b));
        let bSorted = odds.filter(o => o.values.filter(v => v.name === bName).length > 0).sort((a, b) => sortBy(bName, a, b));

        if(aSorted.length === 0)
          aSorted = [{ values: []}];

        if(bSorted.length === 0)
          bSorted = [{ values: []}];

        const { a, b } = { 
          a: { bookmaker: aSorted[0], values: [aSorted[0].values.find(v => v.name === aName)] },
          b: { bookmaker: bSorted[0], values: [bSorted[0].values.find(v => v.name === bName)] },
        } 
        
        return(
          <div className="grid grid-cols-2">
            <div className="flex justify-center items-center">
              {names.length > 0 && (
                <div className="py-2 h-full mr-2">
                  <button 
                    onClick={e => gotoBetIndex(betIndex - 1)}
                    className="border border-border2-base bg-background3-base hover:bg-background2-base rounded h-full px-1"
                  >
                    <ChevronIcon stroke="black" fill="black" width="14" height="15"/>
                  </button>
                </div>
              )}
              <div className="w-full">
                <OddsBox odds={a} value={aName} secondary={aSorted[0].bookmakerId} label={aName.replace('home#', '')} uri={aSorted[0].bookmakerURI } />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="w-full">
                <OddsBox odds={b} value={bName} secondary={bSorted[0].bookmakerId} label={bName.replace('away#', '')} uri={bSorted[0].bookmakerURI } />
              </div>
              {names.length > 0 && (
                <div className="py-2 h-full mr-2 ml-2">
                  <button 
                    onClick={e => gotoBetIndex(betIndex + 1)} 
                    className="border border-border2-base bg-background3-base hover:bg-background2-base rounded h-full px-1"
                  >
                    <ChevronIcon stroke="black" fill="black" width="14" height="15" className="transform rotate-180"/>
                  </button>
                </div>
              )}
            </div>            
          </div>
        );

      })}
    </div>
  )    

}

export { AsianHandicap, BestAsianHandicapOdds };