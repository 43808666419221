import React from "react";
import Page from "../components/Page";

const PrivacyPage = () => {
  return (
    <>
    <Page showAds={true} backButton={true}>
      <h1 className='text-primary2-base'>Privacy policy</h1>
      <p>
        This Privacy Policy outlines how we collect, use, and safeguard your
        personal information in accordance with the General Data Protection
        Regulation (GDPR) requirements.
      </p>
      <p>
        We will take reasonable steps to ensure the security of your personal
        information, but we cannot be held accountable for matters beyond our
        control, such as unauthorized access to our computer system, servers,
        viruses, or hacking.
      </p>
      <p>
        We may collect certain types of information when you access or use our
        website:
        <ul>
          <li>
            <em>Personal Information:</em> We may collect personally
            identifiable information, such as your name, email address, and
            other contact details, only if you voluntarily provide it to us.
          </li>
          <li>
            <em>Non-Personal Information: </em>We may gather non-personal
            information such as browser type, operating system, and website
            usage data to enhance user experience.
          </li>
        </ul>
      </p>
      <p>
        If you provide your email or other details to us, we reserve the right
        to use this information for our own marketing purposes in future
        campaigns.
      </p>
      <p>
        Upon request, we may share information about a user of this website with
        law enforcement, a court of law, to protect ourselves, or with a third
        party if we suspect any breach of the law.
      </p>
      <h3>Use of information</h3>
      <p>
        We use collected information for the following purposes:
        <ul>
          <li>
            <em>Communication:</em> Personal information may be used to respond
            to inquiries, provide requested information, and send updates.
          </li>
          <li>
            <em>Analytics:</em> Non-personal information helps us understand
            user preferences and improve our website's content and
            functionality.
          </li>
        </ul>
      </p>

      <h2>Cookie policy</h2>
      <h3>What Are Cookies?</h3>
      <p>
        Cookies are small text files stored in your browser by the websites you
        visit. They typically contain a website and an identifier. Cookies help
        enhance your experience while navigating the website, such as by
        remembering the settings you've chosen.
      </p>
      <h3>How and Why Do We Use Cookies?</h3>
      <p>
        We use cookies to improve your experience, for instance, by remembering
        when you're logged in. If you fill out a form, we use cookies to
        remember the information for next time.
      </p>
      <h3>Third-Party Cookies</h3>
      <p>
        We use third-party cookies to track how you use our website, such as
        which pages you visit and how long you spend on each page. This helps us
        gain a better understanding of how people use our site and how they
        access it, which in turn helps us create better campaigns and better
        quality.
      </p>
      <h3>Disabling Cookies:</h3>
      <p>
        You can prevent cookies from being placed in your browser settings.
        However, this may impact the functionality of the websites you visit.
        Cookies on our site are not necessary but can enhance your experience.
      </p>
      <p>
        If you want to delete cookies in your browser, you can easily follow one
        of the following guides:
        <ul>
          <li>
            Mozilla Firefox:{" "}
            <a
              href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
              target="_blank"
              rel="noreferrer"
            >
              Clear Cookies and Site Data in Firefox
            </a>
          </li>
          <li>
            Google Chrome:{" "}
            <a
              href="https://support.google.com/chrome/answer/95647"
              target="_blank"
              rel="noreferrer"
            >
              Clear Cookies in Google Chrome
            </a>
          </li>
          <li>
            Internet Explorer 11:{" "}
            <a href="https://support.microsoft.com/sv-se/windows/ta-bort-och-hantera-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">
              Remove and Manage Cookies in Internet Explorer
            </a>
          </li>
        </ul>
      </p>
      </Page>
    </>
  );
};

export default PrivacyPage;
