
import { useEffect, useState } from 'react';
import { ReactComponent as BellIcon } from '../images/bell.svg';

function UpdatedDate({ updatedAt }) {

  const [now, setNow] = useState(new Date().valueOf());

  useEffect(() => {
    setNow(new Date().valueOf())
    let i = setInterval(() => {
      setNow(new Date().valueOf())
    }, 1000);
    return(() => {
      clearInterval(i);
    });
  }, [updatedAt]);

  let d = updatedAt.indexOf('T') === -1 ? updatedAt.replace(' ', 'T') + '.000Z' : updatedAt;
  let int = (now - new Date(d).valueOf()) / 1000;
  let hours = Math.floor(int / 60 / 60);
  let minutes = Math.floor(int / 60) - hours * 60;
  let seconds = Math.floor(int ) - hours * 60 * 60 - minutes * 60;

  return(
    <>
      <BellIcon width={19} height={19} fill="#687182" className="mx-1 ml-2"/>
      {hours > 0 && <span className="text-gray-500 mr-1">{hours}h</span>}
      {minutes > 0 && <span className="text-gray-500 mr-1">{minutes}m</span>}
      {seconds > 0 && <span className="text-gray-500 mr-1">{seconds}s</span>}
      {hours === 0 && minutes === 0 && seconds === 0 && <span className="text-gray-500 mr-1">0s</span>}
    </>
  )

}

export { UpdatedDate }