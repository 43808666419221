import { useState, useEffect } from 'react';

const useResult = (result, defaultData) => {
  const [data, setData] = useState(defaultData || { });
  useEffect(() => {
    if(result)
      setData(result);
  }, [result]);
  return data;
}


export { useResult }