import React, { useContext } from 'react';

import Page from "../components/Page";

import { AuthContext } from '../AuthContext';

const AboutPage = () => {

  const authState = useContext(AuthContext);

  return(
    <Page showAds={true} backButton={true} showTabs={authState.isAuthenticated}>
      <h1 className='text-primary2-base'>About us</h1>
      <p className='mb-4'>The concept behind Betfavor was conceived during the summer of 2021 by a group of five friends residing in the quaint town of Söderhamn, situated in Sweden. Their vision, then and now, revolves around presenting comprehensive data and statistics sourced from various bookmakers. This endeavor is aimed at assisting punters in identifying optimal betting opportunities. By facilitating a comparison of diverse odds and devising betting strategies tailored to individual preferences, Betfavor seeks to empower bettors in making informed decisions.</p>
      <p className='mb-4'>A paramount focus throughout the development of Betfavor has been on ensuring the utmost accuracy and currency of the data presented. The team has strived to maintain up-to-date information, reflecting their dedication to providing users with reliable insights. Notably, Betfavor boasts an unparalleled delay of down to merely 0.6 seconds, setting a new standard for live odds precision among similar platforms.</p>
      <p className='mb-4'>While currently in its initial phase, Betfavor is committed to continuous growth and enhancement. The team harbors a plethora of innovative ideas poised to materialize in the times ahead, enriching the platform's offerings and user experience.</p>
    </Page>
  );
}

export default AboutPage;