
import React, { useState } from 'react';

import LoadingIcons from 'react-loading-icons'

import { CREATE_FAVORITE, REMOVE_FAVORITE } from '../graphql/mutations';
import { useMutation } from "@apollo/client";

import { ReactComponent as StarIcon } from '../images/star.svg';

const favorites_storage_entry = (type) => `favorites_${type}`;

const store_favorites_from_query = (favorites) => {
  let favoritesMap = { }
  for(const fav of favorites) {
    const { relationId: id, type } = fav;
    if(!favoritesMap[type])
      favoritesMap[type] = [];
    favoritesMap[type].push(id);
  }
  for(const favoriteType in favoritesMap) {
    sessionStorage.setItem(favorites_storage_entry(favoriteType), JSON.stringify(favoritesMap[favoriteType]));
  }
}

const FavoriteIcon = ({ type, id }) => {


  const [createFavorite, { loading: isCreatingFavorite }] = useMutation(CREATE_FAVORITE);
  const [removeFavorite, { loading: isRemovingFavorite }] = useMutation(REMOVE_FAVORITE);

  const checkAgainstSessionStorage = () => {
    var entriesInStorage = JSON.parse(sessionStorage.getItem(favorites_storage_entry(type)) || '[]');
    return(entriesInStorage.indexOf(id) > -1);
  }

  const toggleInStorage = (add) => {
    var entriesInStorage = JSON.parse(sessionStorage.getItem(favorites_storage_entry(type)) || '[]');
    const idx = entriesInStorage.indexOf(id);
    if(add) {
      entriesInStorage.push(id);
    } else if(add === false) {
      entriesInStorage.splice(idx, 1);
    }
    sessionStorage.setItem(favorites_storage_entry(type), JSON.stringify(entriesInStorage));
  }

  const [isFavorite, setFavorite] = useState(checkAgainstSessionStorage());
    
  const toggleFavorite = async () => {
    try {
      if(isFavorite) {
        await removeFavorite({ variables: { type, relationId: id } });
        toggleInStorage(false);
      } else {
        await createFavorite({ variables: { type, relationId: id } });
        toggleInStorage(true);
      }      
      setFavorite(checkAgainstSessionStorage(type, id));
    } catch(e) {
      alert('Failed to toggle favorite');
    }
  }
  
  if(isCreatingFavorite || isRemovingFavorite) {
    return(
      <LoadingIcons.Puff style={{ width : 19, height : 19 }} stroke="#5B8C00" className='loading-icon' scale={0.2}/>
    )
  }

  return(
    <StarIcon onClick={toggleFavorite} fill={isFavorite ? "#FFBE00" : '#bbbbbb'} width={19} height={19} className="cursor-pointer"/>
  );

}

export { FavoriteIcon, store_favorites_from_query }