import React, { useState } from 'react';

import { ReactComponent as EmailIcon } from '../images/email.svg';

const TextInput = ({ label, onChange, value, placeHolder, type}) => {

  const [text, setText] = useState(value || '');

  const textChanged = (v) => {
    setText(v.target.value);
    if(typeof(onChange) == 'function') onChange(v.target.value);
  }

  return(
    <div>
      <label className="text-text3-base font-roboto">
        {label}
        <div className="rounded-lg border border-background2-base bg-background3-base p-3 flex justify-start items-center">
          <EmailIcon width="24"/> <input onChange={textChanged} value={text} type={type || 'text'} placeholder={placeHolder} className="bg-background3-base ml-3 w-full text-text2-base"/>
        </div>
      </label>
    </div>
  )
}

export default TextInput;