import React from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as ArrowRight } from '../../images/green-arrow-right.svg';
import { ReactComponent as ClockIcon } from '../../images/clock.svg';
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg';

import { SportIcon } from '../SportIcon';
import { FavoriteIcon } from '../FavoriteIcon';

import { OddsBox } from "../OddsBox";

import { UpdatedDate } from '../UpdatedDate';

import { camelCase } from '../../utils';

function ValuebetRow({ market, match, entry, onLeagueSelected }) {
  
  const { homeTeam, awayTeam, league, sport, updatedAt } = match;
  const { commenceTime } = entry;

  const firstValuebet = entry.best_valuebets.find(o => o.marketId === market);

  return(
    <div className='border-b border-background2-base'>
      <div>
        <div className="grid grid-cols-12 text-text2-base">
          <div className="col-span-6 grid grid-cols-12 text-text2-base p-2">
            <div className="col-span-1 flex justify-center items-center">
              <SportIcon sportId={sport.name} width="32" height="32" fill="#555555" />
            </div>
            <div className="col-span-11 flex justify-start items-center">
              <div className="flex justify-start flex-col ml-2 font-bold">
                <div>
                  <Link to={`/valuebets/${match.id}`} className="text-small text-black flex flex-row">
                    <span className='mr-1'>{homeTeam.name}</span> vs. <span className='ml-1'>{awayTeam.name}</span>
                  </Link>                  
                </div>
                <button 
                  onClick={e => onLeagueSelected(league)} 
                  className="text-xs font-normal text-left hover:underline hover:text-primary2-base"
                >
                  {camelCase(league.name)}
                </button>
              </div>
            </div>            
          </div>
          <div className="col-span-6 text-text2-base">
            <div className='grid grid-cols-3'>
              <div></div>
              <div></div>
              <div>
                <OddsBox odds={firstValuebet} value={firstValuebet.values[0].name} secondary={firstValuebet.values[0].bookmakerId} uri={firstValuebet.values[0].bookmakerURI } />
              </div>
            </div>
          </div>
          <div className='col-span-12 bg-background2-base w-full flex'>
            <div className="flex justify-between w-full pl-2">
              <div className='flex justify-start items-center w-1/2'>
                <div className="flex justify-center items-center">
                  <FavoriteIcon type="event" id={match.id} />
                </div>
                <div className="flex justify-start items-center whitespace-nowrap w-full">
                  {match.isLive && (
                    <div className='w-full flex justify-start items-center'>
                      <span className='text-red font-bold ml-2'>Live</span>
                      <div className='flex justify-start items-center'><UpdatedDate updatedAt={updatedAt} /></div>
                    </div>
                  )}
                  {!match.isLive && (
                    <>
                      <CalendarIcon width={19} height={19} fill="#687182" className="mx-1"/>
                      {new Date(parseInt(commenceTime)).toLocaleDateString()}
                      <ClockIcon width={19} height={19} fill="#687182" className="mx-1 ml-2"/>
                      {new Date(parseInt(commenceTime)).toLocaleTimeString()}
                    </>
                  )}
                </div>
              </div>
              <div className="bg-primary1-base flex justify-start items-center pr-4">
                <ArrowRight  fill="#5B8C00" className="mr-4"/>
                <h6 className="medium text-white italic mr-2">OVERVALUE</h6>
                <h6 className="larger text-white italic">{firstValuebet.overvalue.toFixed(2)}%</h6>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export { ValuebetRow }