import React from 'react';

const defaultAuthState = {
  isAuthenticated: false,
  showLoginModal: false,
  showSignupModal: false,
  showConfirmationModal: false,
  confirmationEmail: '',
  confirmationPassword: ''
}
const AuthContext = React.createContext(defaultAuthState);

export { AuthContext, defaultAuthState }