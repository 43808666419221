import React, { useContext } from 'react';

import Page from '../components/Page';
import { ReactComponent as FootballIcon } from '../images/sporticons/american_football.svg';
import { ReactComponent as CalendarIcon } from '../images/calendar.svg';
import { ReactComponent as ClockIcon } from '../images/clock.svg';
import { ReactComponent as DiagramIcon } from '../images/diagram.svg';
import { ReactComponent as CheckIcon } from '../images/check.svg';
import { ReactComponent as ChevronIcon } from '../images/chevron.svg';

import { AuthContext } from '../AuthContext';

const StartPage = () => {

  const authState = useContext(AuthContext);

  return (
    <Page backgroundColor={'transparent'} showTabs={authState.isAuthenticated}>
      <div className="w-full flex justify-center p-4">
        <div className='flex flex-row w-full'>
          <div className='flex flex-grow flex-col pr-12'>
            <h3 className='text-primary2-base'>Latest surebets</h3>
            <div className='rounded-tl-md rounded-tr-md bg-primary1-base mt-4 px-3 flex justify-between text-white p-1'>
              <div className='flex items-center'>
                <FootballIcon stroke="#fff" fill="#fff" width="18" className="mr-2" />
                <h6>Atlanta Falcons - Philadelphia Eagles</h6>
              </div>
              <div className='flex items-center'>
                <CalendarIcon stroke="#fff" fill="#fff" width="23" className="mr-1" />
                2021-02-12
                <ClockIcon stroke="#fff" fill="#fff" width="23" className="ml-2 mr-1" />
                18:22
              </div>
            </div>
            <div className='bg-background3-base p-4 rounded-bl-md rounded-br-md'>
              <div className='border border-background2-base grid grid-cols-10 bg-white border-b border-background2-base rounded-tl-md rounded-tr-md'>
                <div className='col-span-3 text-center text-text3-base p-1'>1</div>
                <div className='col-span-3 text-center text-text3-base p-1'>X</div>
                <div className='col-span-3 text-center text-text3-base p-1'>2</div>
                <div className='text-center flex items-center justify-center'><DiagramIcon /></div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary1-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-primary1-base'>3.4</span> Betvictor
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-background3-base p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold'>3.4</span> Piwi
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary3-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-secondary4-base'>3.4</span> Sky bet
                  </div>
                </div>
                <div className='p-2'>
                  <div className='bg-primary1-base p-1 text-center text-white rounded-md'>
                    <span>0.98%</span>
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary1-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-primary1-base'>3.4</span> Betvictor
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-background3-base p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold'>3.4</span> Piwi
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary3-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-secondary4-base'>3.4</span> Sky bet
                  </div>
                </div>
                <div className='p-2'>
                  <div className='bg-primary1-base p-1 text-center text-white rounded-md'>
                    <span>0.98%</span>
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary1-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-primary1-base'>3.4</span> Betvictor
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-background3-base p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold'>3.4</span> Piwi
                  </div>
                </div>
                <div className='col-span-3 p-2'>
                  <div className='bg-secondary3-transparent p-1 text-center text-text2-base rounded-md'>
                    <span className='font-bold text-secondary4-base'>3.4</span> Sky bet
                  </div>
                </div>
                <div className='p-2'>
                  <div className='bg-primary1-base p-1 text-center text-white rounded-md'>
                    <span>0.98%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex justify-between p-4 px-5'>
              <div className='flex justify-center items-center text-primary1-base'>
                Show all 50 surebets 
                <CheckIcon className='ml-2'/>
              </div>
              <div className='flex'>
                <button className='bg-primary1-base rounded-md w-7 h-7 flex items-center justify-center mr-1'>
                  <ChevronIcon stroke='#fff' fill='#fff' width="12" height="14"/>
                </button>
                <button className='bg-primary1-base rounded-md w-7 h-7 flex items-center justify-center'>
                  <ChevronIcon className='transform rotate-180' stroke='#fff' fill='#fff' width="12" height="14"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default StartPage;