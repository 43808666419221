import React, { useContext } from 'react';

import Page from "../components/Page";

import { AuthContext } from '../AuthContext';

const LearnPage = () => {

  const authState = useContext(AuthContext);

  return(
    <Page showAds={true} backButton={true} showTabs={authState.isAuthenticated}>
      <>Learn</>
    </Page>
  );

}

export default LearnPage;