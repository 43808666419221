import React from 'react';
import { BestOddsRow } from '../matchrows/BestOddsRow';
import { Spinner } from '../Spinner';

const BestOddsList = ({ 
  paginator, 
  events, 
  market, 
  loading, 
  filter,
  onLeagueSelected
}) => {
    
  const loadingMarkup = (
    <div className='flex justify-center items-center p-12'>
      <Spinner />
    </div>
  )

  const emptyMarkup = (
    <div className='flex justify-center items-center p-12'>
      No entries found
    </div>
  );

  const mergedCommenceEntries = (match, market) => {
    const { commenceEntries } = match;
    let entry = { ...commenceEntries[0] };
    commenceEntries.forEach((e, i) => {
      if(i === 0) return;
      if(market === 'h2h')
        entry.best_h2h_odds = (entry.best_h2h_odds || []).concat(e.best_h2h_odds || []);
      if(market === 'overunder')
        entry.best_overunder_odds = (entry.best_overunder_odds || []).concat(e.best_overunder_odds || []);
      if(market === 'asianhcp')
        entry.best_asianhcp_odds = (entry.best_asianhcp_odds || []).concat(e.best_asianhcp_odds || []);
    })
    return(entry);
  }
    
  return(
    <>
      <div className='mb-2'>
        {paginator}
      </div>
      {loading && loadingMarkup}
      {!loading && events.length === 0 && emptyMarkup}
      {!loading && events.length > 0 && (
        <div>
          <div className='border border-b-0 border-background2-base rounded-tl-md rounded-tr-md'>
            <div className='grid grid-cols-12 p-2 text-text3-base font-bold'>
              <div className='col-span-6 px-1'>
                Match
              </div>
              {market === 'h2h' && (
                <div className='col-span-6 grid grid-cols-3 gap-2'>
                  <div className='text-center'>1</div>
                  <div className='text-center'>X</div>
                  <div className='text-center'>2</div>
                </div>
              )}
              {market === 'overunder' && (
                <div className='col-span-6 grid grid-cols-2 gap-2'>
                  <div className='text-center'>Over</div>
                  <div className='text-center'>Under</div>
                </div>
              )}
              {market === 'asianhcp' && (
                <div className='col-span-6 grid grid-cols-2 gap-2'>
                  <div className='text-center'>Home</div>
                  <div className='text-center'>Away</div>
                </div>
              )}
            </div>
          </div>
          <div className='border border-background2-base rounded-bl-md rounded-br-md'>
            {events.map((match) => {
              const mergedCommenceEntry = mergedCommenceEntries(match, market)
              return(
                <BestOddsRow 
                  key={`${mergedCommenceEntry.id}`} 
                  market={market} 
                  match={match} 
                  entry={mergedCommenceEntry}
                  filter={filter} 
                  onLeagueSelected={onLeagueSelected}
                />
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export { BestOddsList }