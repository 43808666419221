import React, { useState, useCallback, useEffect } from 'react';

import Page from "../components/Page";

import { FilterBar } from '../components/FilterBar';
import { LiveOddsList } from '../components/lists/LiveOddsList';

import { EVENTS, EVENTS_ASIAN, EVENTS_OVERUNDER } from '../graphql/queries';
import { useQuery } from "@apollo/client";

import { useSearchParams } from "react-router-dom";
import { SearchBar } from '../components/Searchbar';
import { Paginator } from '../components/Paginator';

import { useResult } from '../hooks/useResult';

const LiveOddsPage = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const getParams = useCallback((searchParams) => {
    const commenceTime = parseInt(new Date().valueOf() / 1000);
    const page = parseInt(searchParams.get('page') || 1);
    const pageSize = parseInt(searchParams.get('pageSize') || 10);
    const betType = searchParams.get('betType') || 'odds';
    const orderBy = searchParams.get('orderBy') || 'date';
    const market = searchParams.get('market') || 'h2h';
    const query = searchParams.get('query') || '';
    const sports = (searchParams.get('sports') || '').split(',').filter(v => v);
    const leagues = (searchParams.get('leagues') || '').split(',').filter(v => v);
    const bookmakers = (searchParams.get('bookmakers') || '').split(',').filter(v => v);
    const commenceDay = new Date().toISOString().substring(0, 10);
    const favorites = searchParams.get('favorites') === "true" || false; 
    const live = true;
    const first = pageSize;
    const q = market === 'h2h' ? EVENTS : market === 'overunder' ? EVENTS_OVERUNDER : EVENTS_ASIAN;
    return({
      commenceTime, live, first, page, pageSize, betType, sports, leagues, bookmakers, market, orderBy, query, commenceDay, favorites, q
    });
  }, []);

  let [params, setParams] = useState(getParams(searchParams));
  const { loading: isLoading, refetch: refetchEvents, data: eventsResult, startPolling: startPollingEvents } = useQuery(params.q, { variables : params });
  const eventsData = useResult(eventsResult, {});
  
  // poll events every 30 seconds
  startPollingEvents(30000);

  useEffect(()  => {
    const timeout = setTimeout(() => {
      setParams(getParams(searchParams));
    }, 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [searchParams, getParams]);
  
  const paramsChanged = () => {
    searchParams.set('page', 1);
    setSearchParams(searchParams)
  }

  const favoritesChanged = (checked) => {
    searchParams.set('favorites', checked);
    paramsChanged(searchParams);
  }

  const sportsChanged = (key, checked) => {
    let currentSports = (searchParams.get('sports') || '').split(',');
    if(checked && currentSports.indexOf(key) === -1) {
      currentSports.push(key);
    } else if(!checked && currentSports.indexOf(key) !== -1) {
      currentSports = currentSports.filter(v => v !== key);
    }
    searchParams.set('sports', currentSports.filter(v => v));
    paramsChanged(searchParams);
  }

  const onLeagueSelected = (league) => {
    sportsChanged(league.sport.name, true);
    leaguesChanged(league.id, true);
  }

  const leaguesChanged = (key, checked) => {
    let currentLeagues = (searchParams.get('leagues') || '').split(',');
    if(checked && currentLeagues.indexOf(key) === -1) {
      currentLeagues.push(key);
    } else if(!checked && currentLeagues.indexOf(key) !== -1) {
      currentLeagues = currentLeagues.filter(v => v !== key);
    }
    searchParams.set('leagues', currentLeagues.filter(v => v));
    paramsChanged(searchParams);
  }

  const bookmakersChanged = (key, checked) => {
    let currentBookmakers = (searchParams.get('bookmakers') || '').split(',');
    if(checked && currentBookmakers.indexOf(key) === -1) {
      currentBookmakers.push(key);
    } else if(!checked && currentBookmakers.indexOf(key) !== -1) {
      currentBookmakers = currentBookmakers.filter(v => v !== key);
    }
    searchParams.set('bookmakers', currentBookmakers.filter(v => v));
    paramsChanged(searchParams);
  }

  const marketChanged = (market) => {
    searchParams.set('leagues', []);
    searchParams.set('sports', []);
    searchParams.set('market', market);
    paramsChanged(searchParams);
  }

  const queryChanged = (value) => {
    searchParams.set('query', value);
    paramsChanged(searchParams);
  }

  const orderByChanged = (orderBy) => {
    searchParams.set('orderBy', orderBy);
    paramsChanged(searchParams);
  }

  const gotoPage = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  const refresh = () => {
    refetchEvents();
  }
  
  const sortOptions = [
    { value: 'date', label: 'Date' }
  ]

  const filterBar = (
    <FilterBar 
      live={true}
      favorites={params.favorites}
      sports={params.sports}
      leagues={params.leagues}
      market={params.market}
      bookmakers={params.bookmakers}
      favoritesChanged={favoritesChanged}
      sportsChanged={sportsChanged}
      bookmakersChanged={bookmakersChanged}
      leaguesChanged={leaguesChanged}
    />
  );

  const searchBar = (
    <SearchBar 
      query={params.query}
      queryChanged={queryChanged}
      market={params.market}
      marketChanged={marketChanged}
      orderBy={params.orderBy}
      orderByChanged={orderByChanged}
      sortOptions={sortOptions}
    />
  );

  const paginator = (
    <Paginator 
      total={eventsData.events?.paginatorInfo?.total || 0} 
      page={params.page}
      pageSize={params.pageSize} 
      onGotoPage={gotoPage}
      onRefresh={refresh}
      loading={isLoading}
    />
  );

  return(
    <Page
      filterBar={filterBar}
      searchBar={searchBar}      
    >
      <LiveOddsList 
        loading={isLoading}
        market={params.market}
        events={eventsData.events?.data || []} 
        paginator={paginator}
        onLeagueSelected={onLeagueSelected}
      />  
    </Page>
  );
  
}

export default LiveOddsPage;