

const apiUrls = {
  'development': 'http://localhost:80/graphql',
  //'development': 'https://m8zms5w5a0.execute-api.eu-north-1.amazonaws.com/graphql',
  'production':  'https://m8zms5w5a0.execute-api.eu-north-1.amazonaws.com/graphql'
}

const env = {
  apiUri: apiUrls[process.env.NODE_ENV],
}

export default env;
