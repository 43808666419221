import React, { useState, useEffect, useCallback } from 'react';

import { Paginator } from '../Paginator';

import { Select } from "../Select";
import { useQuery } from '@apollo/client';
import { useResult } from '../../hooks/useResult';

import { useSearchParams } from "react-router-dom";

import { SUREBETS_FOR_COMMENCE_ENTRY } from '../../graphql/queries';

const SurebetsForEvent = ({ event, betAmount, betCurrency }) => {

  const availableMarkets = [
    { value: 'h2h', label: 'Head 2 Head' },
    { value: 'overunder', label: 'Over under' },
    { value: 'asianhcp', label: 'Asian handicap' }
  ];

  const firstCommenceEntry = event.commenceEntries[0];
  const defaultMarketId = availableMarkets[0].value;
  const selectableMarkets = [...availableMarkets];

  let [searchParams, setSearchParams] = useSearchParams();

  const getParams = useCallback((searchParams) => {
    const page = parseInt(searchParams.get('page') || 1);
    const pageSize = parseInt(searchParams.get('pageSize') || 20);
    const marketId = searchParams.get('market') || defaultMarketId;
    const commenceEntryId = firstCommenceEntry.id;
    return({
      first: pageSize, pageSize, page, marketId, commenceEntryId
    });
  }, [defaultMarketId, firstCommenceEntry.id]);

  const [params, setParams] = useState(getParams(searchParams));

  const { loading: isLoading, refetch: refetchSurebets, data: surebetsResult } = useQuery(SUREBETS_FOR_COMMENCE_ENTRY, { variables : params });
  const surebetsData = useResult(surebetsResult, {});
  
  useEffect(()  => {
    const timeout = setTimeout(() => {
      setParams(getParams(searchParams));
    }, 500);
    return () => {
      clearTimeout(timeout);
    }
  }, [searchParams, getParams]);

  const selectedMarketChanged = (selectedMarket) => {
    searchParams.set('market', selectedMarket);
    setSearchParams(searchParams);
  }

  const gotoPage = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  const refresh = () => {
    refetchSurebets();
  }
  
  const getBetName = (marketId, name) => {
    switch(marketId) {
      case 'overunder':
      case 'asianhcp':
        return name.split('#')[1];
      default:
        return null;
    }
  }

  const renderBet = (surebet, bet) => {
    const calcBet = () => {
      let tot = 0;
      for(const _b of surebet.values)
        tot += 1 / _b.value;
      return((1 / bet.value) / tot) * betAmount;
    }
    const getTrend = (bookmakerId, currentValue, type) => {
      /*
      const { history : historyForBookmakerAndName } = [ce.odds || []].find(o => o.bookmakerId === bookmakerId && o.marketId === surebet.marketId).values.filter(v => v.name === bet.name);
      if(historyForBookmakerAndName && historyForBookmakerAndName.length > 0) {
        var t = historyForBookmakerAndName[0].value - currentValue;
        if(t < 0) return(type === 'background' ? 'bg-secondary1-transparent' : 'text-primary1-base')
        else if(t > 0) return(type === 'background' ? 'bg-secondary3-transparent' : 'text-secondary4-base')
        else return(type === 'background' ? 'bg-secondary2-transparent' : 'text-text2-base')
      }
      */
      return('');
    }
    const name = getBetName(surebet.marketId, bet.name);
    return(
      <div key={bet.id} className="w-2/6 mx-2 my-1 flex flex-col">
        <div className='text-center border-b border-background2-base p-2 rounded-t-md border-background2-base border-t border-l border-r'>
          {calcBet().toFixed(2)} {betCurrency}
        </div>
        <div className={`text-center p-2 ${getTrend(bet.bookmakerId, bet.value, 'background')} rounded-b-md border-background2-base border-b border-l border-r`}>
          {name && (<><span>{name}</span><br /></>)}
          <span className={`font-bold ${getTrend(bet.bookmakerId, bet.value, 'text')}`}>{bet.value.toFixed(2)}</span> {bet.bookmakerId}
        </div>
      </div>
    );
  }

  const getBetsForMarket = (surebets, marketId) => {
    const bets = [];
    switch(marketId) {
      case 'overunder':
        bets.push(surebets.find(b => b.name.split('#')[0] === 'over'));
        bets.push(surebets.find(b => b.name.split('#')[0] === 'under'));
        break;
      case 'asianhcp':
        bets.push(surebets.find(b => b.name.split('#')[0] === 'home'));
        bets.push(surebets.find(b => b.name.split('#')[0] === 'away'));
        break;
      default:
        bets.push(surebets.find(b => b.name === 'home'));
        bets.push(surebets.find(b => b.name === 'draw'));
        bets.push(surebets.find(b => b.name === 'away'));
        break;
    }
    return(bets);
  }

  return(
    <>
      <div className="mt-2 grid grid-cols-4 border rounded border-background2-base p-4">
        <Select
          label={'Market'}
          options={selectableMarkets}
          value={params.marketId}
          onChange={selectedMarketChanged}
        />
      </div>
      <div className='mt-4 mb-2'>
        <Paginator
          total={surebetsData?.surebets_for_commence_entry?.paginatorInfo?.total || 0} 
          page={params.page}
          pageSize={params.pageSize} 
          onGotoPage={gotoPage}
          onRefresh={refresh}
          loading={isLoading}
        />
      </div>
      <div className="mt-1 rounded border bg-background3-base border-background2-base p-2 text-text3-base flex flex-row">
        {params.marketId === 'h2h' && (
          <>
            <span className="w-2/6 text-center">1</span>
            <span className="w-2/6 text-center">X</span>
            <span className="w-2/6 text-center">2</span>
            <span className="w-2/12 text-center">Profit</span>        
          </>
        )}
        {params.marketId === 'overunder' && (
          <>
            <span className="w-2/6 text-center">Over</span>
            <span className="w-2/6 text-center">Under</span>
            <span className="w-2/6 text-center">Profit</span>        
          </>
        )}
        {params.marketId === 'asianhcp' && (
          <>
            <span className="w-2/6 text-center">Home</span>
            <span className="w-2/6 text-center">Away</span>
            <span className="w-2/6 text-center">Profit</span>        
          </>
        )}
      </div>      
      <div className="mt-2 rounded border-2 bg-white border-background3-base p-1 text-roboto text-text2-base">
        {(surebetsData?.surebets_for_commence_entry?.paginatorInfo?.total) === 0 && (
          <div className="p-4 text-center">
            No surebets found for this market <strong>{params.marketId}</strong>
          </div>  
        )}
        {surebetsData.surebets_for_commence_entry?.data?.map(surebet => {    
          return(
            <div key={surebet.id} className="flex flex-row w-full" >
              {getBetsForMarket(surebet.values, params.market).map((bet) => {
                return(renderBet(surebet, bet))  
              })}
              <div className={`${params.market === 'h2h' ? 'w-2/12' : 'w-2/6'} mx-2 my-1 flex flex-col`}>
                <div className='rounded-t-md text-center border-b border-primary1-light p-2 text-white bg-primary1-light'>
                  {(betAmount * (surebet.profit / 100)).toFixed(2)} {betCurrency}
                </div>
                <div className="text-center rounded-b-md p-1 text-white bg-primary1-base text-lg">
                  {surebet.profit.toFixed(2)}%
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )

}

export { SurebetsForEvent }