
import { Spinner } from './Spinner';

const Paginator = ({ 
  total, 
  page, 
  pageSize, 
  onGotoPage,
  onRefresh,
  loading
}) => {

  let pages = [];
  let numPages = Math.ceil(total / pageSize);
  for(var i = 0; i < numPages; i++) {
    pages.push({ 'label': i + 1, key: i + 1 });
  }

  const startIndex = numPages > 10 && page > 4 ? page - 5 : 0;
  const endIndex = startIndex + 10;

  const paginatorMarkup = (
    <div className='flex justify-between items-center flex-row'>
      <div className='flex justify-center items-center'>
        {numPages > 1 && (
          <>
            {pages.map((p, i) => {                    
              if(i < startIndex || i >= endIndex) return(null);
              const bgClass = p.key === page ? 'bg-primary1-base' : 'bg-white';
              const textColor = p.key === page ? 'text-white' : 'bg-black';
              return(
                <div 
                  key={`page_${i}`} 
                  className={`cursor-pointer ${bgClass} ${textColor} hover:bg-background2-base hover:text-black p-1 border border-primary1-base rounded mr-1 w-8 flex justify-center items-center`} 
                  onClick={e => onGotoPage?.(p.key)}
                >
                  {p.label}
                </div>
              )
            })}
            of
            <span className="ml-1">
              {numPages}
            </span>
          </>
        )}
      </div>
      <div className='flex justify-center items-center my-1 text-text2-base'>
        <button className='mr-1 text-primary1-base' onClick={onRefresh}>
          {loading && (
            <Spinner width={16} height={16} /> 
          )}
          {!loading && (
            <>Refresh</>
          )}
        </button>
      </div>
    </div>
  );
  
  return(
    <>{paginatorMarkup}</>
  )

}

export { Paginator }