import { useContext, useState } from "react";

import { Auth } from 'aws-amplify';

import TextInput from '../TextInput';

import { ReactComponent as GoogleIcon } from '../../images/google.svg';
import { ReactComponent as FacebookIcon } from '../../images/facebook.svg';
import { ReactComponent as EmailIcon } from '../../images/facebook.svg';
import { ReactComponent as PasswordIcon } from '../../images/lock.svg';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import ModalBase from './ModalBase';

import LoadingIcons from 'react-loading-icons'

import { AuthContext } from '../../AuthContext';


const SigninModal = () => {

  const { dispatch: dispatchAuthState } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);

  async function googleSignIn() {
    Auth.federatedSignIn({provider: 'Google'});
  }
  
  async function facebookSignIn() {
    Auth.federatedSignIn({provider: 'Facebook'});
  }
  
  const performLogin = async () => {
    setLoginError(undefined);
    try {
      setIsSigningIn(true);
      await Auth.signIn({ username, password });
      setLoginError(undefined);
      setIsSigningIn(false);
      dispatchAuthState({ type : 'setIsAuthenticated', value : true })
    } catch(e) {
      setIsSigningIn(false);
      setLoginError(e.message);
      console.log('Login error', e.message);
    }
  }

  const closeSignIn = () => {
    dispatchAuthState({ type : 'hideLoginModal' })
  }
  
  const signUp = () => {
    dispatchAuthState({ type : 'showSignupModal' })
  }

  return(
    <ModalBase>
      <div className="bg-white rounded-xl border border-secondary3-base shadow-lg p-8 relative">
        <button onClick={closeSignIn} className="absolute right-4 top-4">
          <CloseIcon width="24" />
        </button>
        <h4 className="text-text1-base">Login</h4>
        <p>Don´t have an account yet? <button onClick={signUp} className="text-primary2-base">Get one for free</button></p>
        <div className="my-4 grid grid-cols-2 gap-4 mt-8">
          <button onClick={googleSignIn} className="bg-background3-base border border-background2-base p-3 rounded-lg text-text2-base flex justify-center items-center">
            <GoogleIcon width="24" className="mr-2" /> Login with Google
          </button>
          <button onClick={facebookSignIn} className="bg-facebookblue-base border border-background4-base p-3 rounded-lg text-white flex justify-center items-center">
            <FacebookIcon width="24" className="mr-2" /> Login with Facebook
          </button>
        </div>
        <div className="flex justify-center items-center text-text3-base font-roboto my-8">
          <div className="col-span-5 border-b w-48 mr-2 border-background1-base"></div>
          <div>or</div>
          <div className="col-span-5 border-b w-48 ml-2 border-background1-base"></div>
        </div>
        <div className="mb-4">
          <TextInput onChange={setUsername} placeHolder="email@domain.com" label="Email adress" icon={EmailIcon} />
        </div>
        <div>
          <TextInput onChange={setPassword} placeHolder="password" type="password" label="Password" icon={PasswordIcon} />
        </div>
        <div className="mt-2">
          <label className="font-roboto text-text3-base flex justify-start items-center">
            <input type="checkbox" className="mr-2"/>
            <span>Remember by details</span>
          </label>
        </div>
        {loginError && (
          <div className="text-secondary4-base my-2 bg-secondary3-transparent p-2">
            {loginError}
          </div>
        )}
        <div className="mt-4">
          <button onClick={performLogin} className="bg-primary1-base rounded-lg flex justify-center items-center text-white w-full p-2">
            {!isSigningIn && 'Login'}
            {isSigningIn && (<LoadingIcons.Puff style={{ width : 24, height : 24, marginRight : 10 }} className='loading-icon' scale={0.2}/>)}
          </button>
        </div>
      </div>
    </ModalBase>
  )
}

export { SigninModal }
