
import { useEffect, useState } from "react";

import { OddsBox, ValueBox } from "./OddsBox";
import { ReactComponent as ChevronIcon } from '../images/chevron.svg';

const marketMapping = {
  'h2h': 'Head to head',
  'overunder': 'Over under',
  'asianhcp': 'Asian Handicap',
}

const ClickableHeader = ({ onClick, left, label, selected, textColor, isReversed }) => {
  return(
    <div onClick={onClick} className={`flex ${left ? 'justify-start' : 'justify-center'} cursor-pointer ${textColor || 'text-text2-base'} ${selected ? 'font-bold' : ''}`}>
      {label} {textColor}
      {selected && (
        <ChevronIcon width="8" fill={textColor} className={`ml-2 transform ${isReversed ? '-rotate-90' : 'rotate-90'}`} />
      )}
    </div>
  )
}

const Head2Head = ({event}) => {
  
  const [orderBy, setOrderBy] = useState('home');
  const [orderDesc, setOrderDesc] = useState(true);

  const setOrder = (v) => {
    if(orderBy === v)
      setOrderDesc(!orderDesc)
    else {
      setOrderDesc(true);
    }
    setOrderBy(v);
  }

  const getValue = (what, fromWhat) => {
    return(
      <OddsBox odds={fromWhat} value={what} uri={fromWhat.bookmakerURI} />
    )
  }

  const getPayout = (fromWhat) => {
    var home = fromWhat.values.find(v => v.name.toLowerCase() === 'home');
    var away = fromWhat.values.find(v => v.name.toLowerCase() === 'away');
    var draw = fromWhat.values.find(v => v.name.toLowerCase() === 'draw');
    if(!home || !away) return(null);
    var tot = (1 / home.value) + (1 / away.value);
    if(draw)
      tot += (1 / draw.value);
    tot = (1 + (1 - tot)) * 100;
    return(
      <ValueBox value={tot.toFixed(1) + '%'} backgroundColor="bg-primary1-base" textColor="text-white" />      
    )
  }

  let allOdds = [];
  for(const ce of event.commenceEntries) { 
    for(const o of ce.odds || []) {
      if(o.marketId !== 'h2h')
        continue;
      if(o.values.length > 0)
        allOdds.push(o);
    }
  }
  
  var bestA = 0; var bestB = 0; var bestC = 0;
  var bestABookie = ''; var bestBBookie = ''; var bestCBookie = '';
  var bestABookieUrl = ''; var bestBBookieUrl = ''; var bestCBookieUrl = '';
  for(const odds of allOdds) {
    if((odds.values.filter(v => v.name === 'home')[0]?.value || 0) > bestA) {
      bestA = odds.values.find(v => v.name === 'home').value;
      bestABookie = odds.bookmakerId;
      bestABookieUrl = odds.bookmakerURI;
    }
    if((odds.values.filter(v => v.name === 'draw')[0]?.value || 0) > bestB) {
      bestB = odds.values.find(v => v.name === 'draw').value;
      bestBBookie = odds.bookmakerId;
      bestBBookieUrl = odds.bookmakerURI;
    }
    if((odds.values.filter(v => v.name === 'away')[0]?.value || 0) > bestC) {
      bestC = odds.values.find(v => v.name === 'away').value;
      bestCBookie = odds.bookmakerId;
      bestCBookieUrl = odds.bookmakerURI;
    }
  }

  var v = ((1 / bestA) + (1 / bestC));
  if(bestB) 
    v += (1 / bestB);
    
  var totalPayout = parseFloat((1 + (1.0 - v)) * 100.0);
  
  const sortOdds = (a, b) => {
    if(orderBy === 'home') {
      var va = a.values.find(v => v.name === 'home');
      var vb = b.values.find(v => v.name === 'home');
      if(!va) return(1)
      if(!vb) return(1)
      if(!orderDesc)
        return(va.value - vb.value)
      return(vb.value - va.value)
    }
    else if(orderBy === 'away') {
      var va2 = a.values.find(v => v.name === 'away');
      var vb2 = b.values.find(v => v.name === 'away');
      if(!va2) return(1)
      if(!vb2) return(1)
      if(!orderDesc)
        return(va2.value - vb2.value)
      return(vb2.value - va2.value)
    }
    else if(orderBy === 'draw') {
      var va3 = a.values.find(v => v.name === 'draw');
      var vb3 = b.values.find(v => v.name === 'draw');
      if(!va3) return(1)
      if(!vb3) return(1)
      if(!orderDesc)
        return(va3.value - vb3.value)
      return(vb3.value - va3.value)
    } else if(orderBy === 'bookmaker') {
      if(!orderDesc)
        return(b.bookmakerId.localeCompare(a.bookmakerId));
      return(a.bookmakerId.localeCompare(b.bookmakerId));
    }
  }

  return(
    <>
      <div className="rounded mt-4 p-1 grid grid-cols-8">
        <div className="col-span-4 ">
          <ClickableHeader
            left
            label="Bookmaker"
            onClick={e => setOrder('bookmaker')}
            selected={orderBy === 'bookmaker'}
            isReversed={orderDesc}
          />
        </div>
        <ClickableHeader
          label="Home"
          onClick={e => setOrder('home')}
          selected={orderBy === 'home'}
          isReversed={orderDesc}
        />
        <ClickableHeader
          label="Draw"
          onClick={e => setOrder('draw')}
          selected={orderBy === 'draw'}
          isReversed={orderDesc}
        />
        <ClickableHeader
          label="Away"
          onClick={e => setOrder('away')}
          selected={orderBy === 'away'}
          isReversed={orderDesc}
        />
      </div>
      <div className="border border-background2-base rounded">
        {bestA > 0 && bestC > 0 && (
          <div className=" border-b border-background2-base bg-background3-base grid grid-cols-8">
            <div className="col-span-4 text-text2-base px-2 py-2 flex items-center">Best available odds</div>          
            <div><ValueBox value={bestA.toFixed(2)} backgroundColor={'bg-background1-base'} secondary={bestABookie} uri={bestABookieUrl} horizontal /></div>
            <div>{bestB > 0 && (<ValueBox value={bestB.toFixed(2)} backgroundColor={'bg-background1-base'} secondary={bestBBookie} uri={bestBBookieUrl} horizontal />)}</div>
            <div><ValueBox value={bestC.toFixed(2)} backgroundColor={'bg-background1-base'} secondary={bestCBookie} uri={bestCBookieUrl} horizontal /></div>
            <div><ValueBox value={totalPayout.toFixed(1) + '%'} backgroundColor="bg-primary1-base" textColor="text-white" /></div>
          </div>
        )}
        {allOdds.sort(sortOdds).map((o, i) => {
          return(
            <div className=" border-b border-background2-base grid grid-cols-8" key={i}>
              <div className="col-span-4 text-text2-base px-2 py-2 flex items-center">{o.bookmakerId}</div>
              <div>{getValue('home', o)}</div>
              <div>{getValue('draw', o)}</div>
              <div>{getValue('away', o)}</div>
              <div>{getPayout(o)}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const OverUnder = ({event, betValue}) => {
  
  const overName = `over#${betValue}`;
  const underName = `under#${betValue}`;

  const [orderBy, setOrderBy] = useState(overName);
  const [orderDesc, setOrderDesc] = useState(true);

  const setOrder = (v) => {
    if(orderBy === v)
      setOrderDesc(!orderDesc)
    else {
      setOrderDesc(true);
    }
    setOrderBy(v);
  }

  const getValue = (what, fromWhat) => {
    return(
      <OddsBox odds={fromWhat} value={what} uri={fromWhat.bookmakerURI} />
    )
  }
  let allOdds = [];
  for(const ce of event.commenceEntries) { 
    for(const o of ce.odds || []) {
      if(o.marketId !== 'overunder')
        continue;
      if(o.values.length === 0)
        continue;
      var overValue = o.values.find(v => v.name === overName);
      var underValue = o.values.find(v => v.name === underName);
      if(overValue && underValue)
        allOdds.push(o);
    }
  }
      
  var bestOver = 0; var bestUnder = 0;
  var bestOverBookie = ''; var bestUnderBookie = '';
  var bestOverBookieUrl = ''; var bestUnderBookieUrl = '';

  for(const odds of allOdds) {
    if((odds.values.filter(v => v.name === overName)[0]?.value || 0) > bestOver) {
      bestOver = odds.values.find(v => v.name === overName).value;
      bestOverBookie = odds.bookmakerId;
      bestOverBookieUrl = odds.bookmakerURI;
    }
    if((odds.values.filter(v => v.name === underName)[0]?.value || 0) > bestUnder) {
      bestUnder = odds.values.find(v => v.name === underName).value;
      bestUnderBookie = odds.bookmakerId;
      bestUnderBookieUrl = odds.bookmakerURI;
    }
  }

  var v = ((1 / bestOver) + (1 / bestUnder));
    
  var totalPayout = parseFloat((1 + (1.0 - v)) * 100.0);

  const getPayout = (fromWhat) => {
    var over = fromWhat.values.find(v => v.name.toLowerCase() === overName);
    var under = fromWhat.values.find(v => v.name.toLowerCase() === underName);
    if(!over || !under) return(null);
    var tot = (1 / over.value) + (1 / under.value);
    tot = (1 + (1 - tot)) * 100;
    return(
      <ValueBox value={tot.toFixed(1) + '%'} backgroundColor="bg-primary1-base" textColor="text-white" />      
    )
  }

  const sortOdds = (a, b) => {
    if(orderBy === overName || orderBy === underName) {
      var va = a.values.find(v => v.name === (orderBy === overName ? overName : underName));
      var vb = b.values.find(v => v.name === (orderBy === overName ? overName : underName));
      console.log(orderBy, va.value, vb.value);
      if(!va) return(1);
      if(!vb) return(1);
      if(!orderDesc)
        return(parseFloat(va.value) - parseFloat(vb.value));
      return(parseFloat(vb.value) - parseFloat(va.value));
    } else if(orderBy === 'bookmaker') {
      if(!orderDesc)
        return(b.bookmakerId.localeCompare(a.bookmakerId));
      return(a.bookmakerId.localeCompare(b.bookmakerId));
    }
  }

  return(
    <>
      <div className="rounded mt-4 p-1 grid grid-cols-8">
        <div className="col-span-5">
          <ClickableHeader
            left
            label="Bookmaker"
            onClick={e => setOrder('bookmaker')}
            selected={orderBy === 'bookmaker'}
            isReversed={orderDesc}
          />
        </div>
        <ClickableHeader
          label={`Over ${betValue}`}
          onClick={e => setOrder(overName)}
          selected={orderBy === overName}
          isReversed={orderDesc}
        />
        <ClickableHeader
          label={`Under ${betValue}`}
          onClick={e => setOrder(underName)}
          selected={orderBy === underName}
          isReversed={orderDesc}
        />
      </div>
      
      <div className="border border-background2-base rounded">
        <div className=" border-b border-background2-base bg-background3-base grid grid-cols-8">
          <div className="col-span-5 text-text2-base px-2 py-2 flex items-center">Best available odds</div>          
          <div><ValueBox value={bestOver.toFixed(2)} backgroundColor={'bg-background1-base'} secondary={bestOverBookie} uri={bestOverBookieUrl} horizontal /></div>
          <div><ValueBox value={bestUnder.toFixed(2)} backgroundColor={'bg-background1-base'} secondary={bestUnderBookie} uri={bestUnderBookieUrl} horizontal /></div>
          <div><ValueBox value={totalPayout.toFixed(1) + '%'} backgroundColor="bg-primary1-base" textColor="text-white" /></div>
        </div>
        {allOdds.sort(sortOdds).map((o, i) => {
          return(
            <div className=" border-b border-background2-base grid grid-cols-8" key={i}>
              <div className="col-span-5 text-text2-base px-2 py-2 flex items-center">{o.bookmakerId}</div>
              <div>{getValue(overName, o)}</div>
              <div>{getValue(underName, o)}</div>
              <div>{getPayout(o)}</div>
            </div>
          )
        })}
      </div>
    </>
  )
}


const AsianHandicap = ({event, betValue}) => {
  
  const positiveBetValue = parseFloat('+' + betValue);
  const negativeBetValue = parseFloat('-' + betValue) < 0 ? parseFloat('-' + betValue) : betValue;

  const homePositiveName = `home#${positiveBetValue}`;
  const awayNegativeName = `away#${negativeBetValue}`;
  const homeNegativeName = `home#${negativeBetValue}`;
  const awayPositiveName = `away#${positiveBetValue}`;

  const [orderBy, setOrderBy] = useState('home');
  const [orderDesc, setOrderDesc] = useState(true);

  const setOrder = (v) => {
    if(orderBy === v)
      setOrderDesc(!orderDesc)
    else {
      setOrderDesc(true);
    }
    setOrderBy(v);
  }

  const getValue = (what, label, fromWhat) => {
    return(
      <OddsBox odds={fromWhat} value={what} uri={fromWhat.bookmakerURI} label={label} />
    )
  }

  let allOdds = [];
  /*
  for(const ce of event.commenceEntries) { 
    for(const o of ce.odds || []) {
      if(o.marketId !== 'asianhcp')
        continue;
      if(o.values.length === 0)
        continue;
      var homePositive = o.values.find(v => v.name === homePositiveName);
      var awayNegative = o.values.find(v => v.name === awayNegativeName);
      var homeNegative = o.values.find(v => v.name === homeNegativeName);
      var awayPositive = o.values.find(v => v.name === awayPositiveName);
      if((homePositive && awayNegative) || (homeNegative && awayPositive))
        allOdds.push({
          ...o,
          values: o.values.filter((v) => {
            return(v.name === homePositiveName || v.name === awayNegativeName || v.name === homeNegativeName || v.name === awayPositiveName)
          })
        });
    }
  }*/

  var homeValues = [];
  var awayValues = [];

  for(const ce of event.commenceEntries) {
    for(const o of ce.odds || []) {
      if(o.marketId !== 'asianhcp')
        continue;
      if(o.values.length === 0)
        continue;
      for(const v of o.values) {
        if(v.name === homePositiveName || v.name === homeNegativeName) {
          homeValues.push({ v, odds: o });
        }
        if(v.name === awayPositiveName || v.name === awayNegativeName) {
          awayValues.push({ v, odds: o });
        }
      }
    }
  }

  const sortOdds = (a, b) => {
    if(orderBy === 'home' || orderBy === 'away') {
      var va = a.v.value;
      var vb = b.v.value;
      if(!va) return(1);
      if(!vb) return(1);
      if(!orderDesc)
        return(parseFloat(va) - parseFloat(vb));
      return(parseFloat(vb) - parseFloat(va));
    } else if(orderBy === 'bookmaker') {
      if(!orderDesc)
        return(b.odds.bookmakerId.localeCompare(a.odds.bookmakerId));
      return(a.odds.bookmakerId.localeCompare(b.odds.bookmakerId));
    }
  }

  return(
    <>
      <div className="rounded mt-4 p-1 grid grid-cols-8">
        <div className="col-span-6">
          <ClickableHeader
            left
            label="Bookmaker"
            onClick={e => setOrder('bookmaker')}
            selected={orderBy === 'bookmaker'}
            isReversed={orderDesc}
          />
        </div>
        <ClickableHeader
          label={`Home`}
          onClick={e => setOrder('home')}
          selected={orderBy === 'home'}
          isReversed={orderDesc}
        />
        <ClickableHeader
          label={`Away`}
          onClick={e => setOrder('away')}
          selected={orderBy === 'away'}
          isReversed={orderDesc}
        />
      </div>
      
      <div className="border border-background2-base rounded">
        {[ ...(orderBy === 'away' ? awayValues : homeValues)].sort(sortOdds).map((o, i) => {
          let homeName = o.v.name;
          let awayName = o.v.name;
          switch(homeName) {
            case awayPositiveName:
              homeName = homeNegativeName;
              break;
            case awayNegativeName:
              homeName = homePositiveName;
              break;
            case homePositiveName:
              awayName = awayNegativeName;
              break;
            case homeNegativeName:
              awayName = awayPositiveName;
              break;
            default:
              awayName = '';
              break;
          }
          return(
            <div className="border-b border-background2-base grid grid-cols-8" key={i}>
              <div className="col-span-6 text-text2-base px-2 py-2 flex items-center">{o.odds.bookmakerId}</div>
              <div>
                {getValue(homeName, homeName.replace('home#', ''), o.odds)}
              </div>
              <div>
                {getValue(awayName, awayName.replace('away#', ''), o.odds)}
              </div>
            </div>
          );
        })}
      </div>
    </>
  )
}

const OddsRow = ({ odds: oddsSettings, event }) => {

  const [marketDialogVisible, setMarketDialogVisible] = useState(false);
  const [betDialogVisible, setBetDialogVisible] = useState(false);

  const [selectedMarket, setSelectedMarket] = useState(oddsSettings.market);
  const [betValue, setBetValue] = useState('2.5');
  const [uniqueOverUnderValues, setUniqueOverUnderValues] = useState([]);
  const [uniqueHandicapValues, setUniqueHandicapValues] = useState([]);

  const setMarket = (market) => {
    setSelectedMarket(market);
    setMarketDialogVisible(false);
  }

  const availableMarkets = [
    { value: 'h2h', label: 'Head 2 Head' },
    { value: 'overunder', label: 'Over under' },
    { value: 'asianhcp', label: 'Asian handicap' }
  ]; 
  
  useEffect(() => {
    if(selectedMarket === 'overunder') {
      const _uniqueOverUnderValues = [];
      for(const ce of event.commenceEntries) {
        for(const o of ce.odds || []) {
          if(o.marketId === 'overunder') {
            for(const v of o.values) {
              var ouVal = v.name.split('#')[1];
              if(_uniqueOverUnderValues.indexOf(ouVal) === -1)
                _uniqueOverUnderValues.push(ouVal);
            }
          }
        }
      }
      setUniqueOverUnderValues(_uniqueOverUnderValues);
      setBetValue(_uniqueOverUnderValues.find(v => parseFloat(v) === 2.5) || _uniqueOverUnderValues[0] || '2.5');
    } else if(selectedMarket === 'asianhcp') {
      const _uniqueHandicapValues = [];
      for(const ce of event.commenceEntries) {
        for(const o of ce.odds || []) {
          if(o.marketId === 'asianhcp') {
            for(const v of o.values) {
              var handicapVal = v.name.split('#')[1]
                .replace('+', '')
                .replace('-', '');
              if(_uniqueHandicapValues.indexOf(handicapVal) === -1)
              _uniqueHandicapValues.push(handicapVal);
            }
          }
        }
      }
      setUniqueHandicapValues(_uniqueHandicapValues);
      setBetValue(_uniqueHandicapValues.find(v => parseFloat(v) === 0) || _uniqueHandicapValues[0] || '0');
    }
  }, [event, selectedMarket]);

  return(
    <div >
      <div className="grid grid-cols-3 gap-2">
        <div>
          <div className="p-1">
            <label>Bet type</label>
          </div>
          <div>
            <div onClick={e => setMarketDialogVisible(!marketDialogVisible)} className="bg-background2-base border-background2-base border p-2 rounded w-full text-left relative">
              {marketMapping[selectedMarket]}
              {marketDialogVisible && (
                <div className="absolute bg-white border-background2-base border rounded shadow flex flex-col w-full">
                  {availableMarkets.map((m, i) => {
                    return(
                      <button key={m.value} className="border-b border-background2-base p-2 hover:bg-background2-base" onClick={e => setMarket(m.value)}>{m.label}</button>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {selectedMarket === 'overunder' && (
          <div>
            <div className="p-1">
              <label>Bet</label>
            </div>
            <div>
              <div onClick={e => setBetDialogVisible(!betDialogVisible)} className="bg-background2-base border-background2-base border p-2 rounded w-full text-left relative">
                Over/under {betValue}
                {betDialogVisible && (
                  <div className="absolute bg-white border-background2-base border rounded shadow flex flex-col w-full">
                    {uniqueOverUnderValues.map((m) => {
                      return(
                        <button key={m} className="border-b border-background2-base p-2 hover:bg-background2-base" onClick={e => setBetValue(m)}>Over/under {m}</button>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {selectedMarket === 'asianhcp' && (
          <div>
            <div className="p-1">
              <label>Bet</label>
            </div>
            <div>
              <div onClick={e => setBetDialogVisible(!betDialogVisible)} className="bg-background2-base border-background2-base border p-2 rounded w-full text-left relative">
                +/- {betValue}
                {betDialogVisible && (
                  <div className="absolute bg-white border-background2-base border rounded shadow flex flex-col w-full">
                    {uniqueHandicapValues.map((m) => {
                      return(
                        <button key={m} className="border-b border-background2-base p-2 hover:bg-background2-base" onClick={e => setBetValue(m)}>+/- {m}</button>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedMarket === 'h2h' && (
        <Head2Head event={event} />
      )}
      {selectedMarket === 'overunder' && (
        <OverUnder event={event} betValue={betValue} />
      )}
      {selectedMarket === 'asianhcp' && (
        <AsianHandicap event={event} betValue={betValue} />
      )}
    </div>
  );

}

const OddsBuilder = ({event}) => {

  const [listOfOdds] = useState([{ market: 'h2h' }]);

  return(
    <div className="border border-background2-base rounded mt-4 p-2">
      {listOfOdds.map((o, i) => {
        return(
          <OddsRow key={i} odds={o} event={event} />
        )
      })}
    </div>
  )

}

export { OddsBuilder };