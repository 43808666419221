import React, { useContext, useState, useCallback, useEffect } from 'react';

import { AuthContext } from '../AuthContext';

import { ReactComponent as AvatarIcon } from '../images/avatar.svg';
import { ReactComponent as ChevronIcon } from '../images/chevron.svg';

import { useNavigate } from "react-router"
import { Auth } from 'aws-amplify';

const UserMenu = () => {

  const navigate = useNavigate();

  const authState = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
  
  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.closest('.user-menu')) return;
      setExpanded(false);
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);
  
  const logout = () => {
    Auth.signOut();
    sessionStorage.clear();
    authState.dispatch({ type : 'setIsAuthenticated', value : false })
  }

  const gotoSettings = () => {
    navigate('/account')
  }

  return(
    <div className="flex justify-end items-center">
      {/*
        <div className="px-2 border-l border-r border-line2">
          <BellIcon stroke="#fff" fill="#fff" width="16"/>
        </div>
      */}
      <div onClick={toggleExpanded} className="relative ml-2 flex justify-start items-center user-menu cursor-pointer">
        <AvatarIcon width="32"/>
        <ChevronIcon width="10" fill="white" className={`ml-1 transform ${expanded ? '-rotate-90' : '-rotate-180'}`} />
        {expanded && (
        <div className="absolute font-roboto text-text2-base -left-20 top-12 bg-primary1-base rounded-b shadow flex flex-col w-32">
          <button onClick={gotoSettings} className="whitespace-nowrap hover:bg-primary1-light text-white p-2">My account</button>
          <button onClick={logout} className="whitespace-nowrap hover:bg-primary1-light text-white p-2 rounded-b">Log out</button>
        </div>
      )}
      </div>
    </div>
  )
}

export { UserMenu }