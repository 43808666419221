import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

const Tabs = ({ selectedTab }) => {

  const tabs = [
    {
      page: '/',
      label: 'Home'
    },
    {
      page: '/surebets',
      label: 'Surebets'
    },
    {
      page: '/valuebets',
      label: 'Valuebets'
    },
    {
      page: '/odds',
      label: 'Best odds'
    },
    
    {
      page: '/live',
      label: 'Live odds'
    }
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const didClickNavigate = (page) => {
    navigate(page)
  }
  
  return(
    <div className="content">
      {tabs.map((t, i) => {
        let selected = t.page === location.pathname;
        if(selectedTab)
          selected = selectedTab === t.page;
        return(
          <button key={`b_${i}`} onClick={e => didClickNavigate(t.page)} className={`p-4 text-text3-base ${selected ? 'bg-primary1-transparent' : ''}`}>{t.label}</button>
        )
      })}
    </div>
  );

}


export { Tabs }