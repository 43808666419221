import React, { useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { EVENT_DETAILS } from '../graphql/queries';
import { useQuery } from "@apollo/client";

import { BetCalculator } from '../components/BetCalculator';
import { SportIcon } from '../components/SportIcon';

import { ReactComponent as ClockIcon } from '../images/clock.svg';
import { ReactComponent as CalendarIcon } from '../images/calendar.svg';
import { ReactComponent as BellIcon } from '../images/bell.svg';
import { SettingsContext } from '../SettingsContext';

import Page from '../components/Page';
import { Spinner } from '../components/Spinner';

import { OddsBuilder } from '../components/OddsBuilder';
import { SurebetsForEvent } from '../components/lists/SurebetsForEvent';
import { ValuebetsForEvent } from '../components/lists/ValuebetsForEvent';

const MatchDetails = ({oddsType}) => {

  const { state: settingsState } = useContext(SettingsContext);

  const location = useLocation();

  const params = useParams();
  const { id } = params;

  const { loading: isLoading, data: eventData } = useQuery(EVENT_DETAILS, { variables : { id } });
  const { event } = eventData || {};

  const loadingMarkup = (
    <div className="p-8 flex justify-center items-center">
      <Spinner />
    </div>
  );
  
  const betAmount = settingsState.betAmount;
  const betCurrency = settingsState.betCurrency;

  const detailsMarkup = isLoading ? loadingMarkup : (() => {
    const commenceTime = parseInt(event.commenceEntries[0].commenceTime);
    return(
      <div>
        <div className="grid grid-cols-2 gap-2">
          <div className="rounded border-2 bg-background3-base border-background2-base p-2 flex justify-center items-center flex-col">
            <SportIcon sportId={event.sport.name} width="50" height="50" fill="#5B8C00" />
            <h6 className="text-primary1-base font-roboto font-bold mb-3 mt-3">
              {event.homeTeam.name} - {event.awayTeam.name}
            </h6>
            <div className="grid grid-cols-2 text-text2-base gap-x-12 gap-y-1 font-medium font-roboto">
              <div>{event.league.name}</div>
              <div className="flex flex-row items-center justify-start"><BellIcon fill="#687182" className="mr-1"/>Updated {new Date(event.updatedAt).toLocaleTimeString()}</div>
              <div className="flex flex-row items-center justify-start"><CalendarIcon fill="#687182" className="mr-1"/>{new Date(commenceTime).toLocaleDateString()}</div>
              <div className="flex flex-row items-center justify-start"><ClockIcon fill="#687182" className="mr-1"/>{new Date(commenceTime).toLocaleTimeString()}</div>
            </div>
          </div>
          <div className="rounded border-2 bg-white border-background2-base p-2 flex flex-col items-center justify-center">
            <BetCalculator.Settings />
          </div>
        </div>
        {oddsType === 'surebets' && (
          <SurebetsForEvent 
            event={event} 
            betAmount={betAmount} 
            betCurrency={betCurrency} 
          />
        )}
        {oddsType === 'valuebets' && (
          <ValuebetsForEvent 
            event={event} 
            betAmount={betAmount} 
            betCurrency={betCurrency} 
          />
        )}
        {(oddsType === 'odds' || oddsType === 'live') && (
          <OddsBuilder 
            event={event} 
            betAmount={betAmount} 
            betCurrency={betCurrency} 
          />
        )}
      </div>
    )
  })();

  const selectedTab = location.pathname.substring(0, location.pathname.substring(1).indexOf('/') + 1);

  return(
    <Page
      backButton
      selectedTab={selectedTab}
    >
      {detailsMarkup}
    </Page>
  )
}

export default MatchDetails;