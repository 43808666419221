import React, { useEffect, useState } from 'react';

import Page from '../components/Page';
import { Input } from '../components/Input';

import { Auth } from 'aws-amplify';
import { Spinner } from '../components/Spinner';

const AccountPage = () => {
 
  const [email, setEmail] = useState('');
  const [emailConfirmationCode, setEmailConfirmationCode] = useState('');
  const [doEditEmail, setDoEditEmail] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isEmailProvidedExternally, setIsEmailProvidedExternally] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setIsEmailProvidedExternally(
        user.username.indexOf('google_') === 0 ||
        user.username.indexOf('facebook_') === 0
      );
      setIsEmailVerified(user.attributes?.email_verified);
      setEmail(user.attributes?.email);
    });
  }, []);

  const emailChanged = (value) => {
    setEmail(value);
  }

  const editEmail = () => { 
    setDoEditEmail(true);
  }

  const saveEmail = () => {
    Auth.updateUserAttributes(Auth.user, { email: email }).then((r) => {
      if(r === 'SUCCESS') {
        setDoEditEmail(false);
        Auth.currentAuthenticatedUser().then(user => {
          setIsEmailVerified(user.attributes?.email_verified);
        });
      }
    });
  }

  const cancelEditEmail = () => {
    setDoEditEmail(false);
  }

  const confirmEmail = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', emailConfirmationCode)
    .then((r) => {
      if(r === 'SUCCESS') {
        setIsEmailVerified(true);
        setDoEditEmail(false);
      }
    }).catch((e) => {
      if(e.code === 'ExpiredCodeException') {
        alert('Confirmation code has expired');
      } else {
        alert('Could not confirm email');
      }
    });
  }

  const confirmationCodeChange = (value) => {
    setEmailConfirmationCode(value);
  }

  const sendConfirmationCodeAgain = () => {
    Auth.verifyCurrentUserAttribute('email').then((r) => {
      if(r === 'SUCCESS') {
        alert('Confirmation code sent');
      } else {
        alert('Could not send confirmation code');
      }
    });
  }

  const cancelConfirmEmail = () => {
    setIsEmailVerified(true);
    setDoEditEmail(true);
  }

  const passwordChanged = (value) => {
    setPassword(value);
  }

  const passwordConfirmationChanged = (value) => {
    setPasswordConfirmation(value);
  }

  const currentPasswordChanged = (value) => {
    setCurrentPassword(value);
  }
  
  const changePassword = async () => {
    if(password !== passwordConfirmation) {
      alert('Passwords do not match');
      return;
    }
    
    try {
      setIsChangingPassword(true);
      let user = await Auth.currentAuthenticatedUser()
      let r = await Auth.changePassword(user, currentPassword, password);
      if(r === 'SUCCESS') {
        setIsPasswordChanged(true);
      }
      setCurrentPassword('');
      setPassword('');
      setPasswordConfirmation('');
    } catch (e) {
      console.error(e)
      if(e.code === 'NotAuthorizedException') {
        alert('Current password is incorrect');
      } else {
        alert('Could not change password');
      }
    } finally {
      setIsChangingPassword(false);
    }
  }

  const passwordMarkup = (
    <>
      <div className='mb-2'>
        {isPasswordChanged && (
          <div className='my-2'>
            <p>Your password has been changed!</p>
          </div>
        )}
        {!isPasswordChanged && (
          <>
            <Input 
              label="Current password"
              onChange={currentPasswordChanged} 
              value={currentPassword} 
              placeHolder='Enter current password' 
              showClearButton={false}
              secure={true}
            />
            <Input 
              label="New password"
              onChange={passwordChanged} 
              value={password} 
              placeHolder='Enter new password' 
              showClearButton={false}
              secure={true}
            />
            <Input 
              label="New password again"
              onChange={passwordConfirmationChanged} 
              value={passwordConfirmation} 
              placeHolder='Enter new password again' 
              showClearButton={false}
              secure={true}
            />
          </>
        )}
      </div>  
      <div className='flex'>    
        {!isPasswordChanged && (          
          <button onClick={changePassword} className='rounded bg-primary1-base text-white flex justify-center items-center p-2'>
            {!isChangingPassword && (
              <span>Change password</span>
            )}
            {isChangingPassword && (
              <div className='p-1 w-28'>
                <Spinner fill width={18} height={12} stroke="#fff" />
              </div>
            )}
          </button>
        )}
        {isPasswordChanged && (
          <button onClick={() => setIsPasswordChanged(false)} className='rounded bg-primary1-base text-white flex justify-center items-center p-2'>
            Change
          </button>
        )}
      </div>
    </>
  );

  const emailMarkup = (
    <>
      <div className='mb-2'>          
        {isEmailVerified && (
          <div className='my-2'>
            <p>Your email has been verified!</p>
          </div>
        )}
        {isEmailVerified && (
          <Input 
            label="Email"
            onChange={emailChanged} 
            value={email} 
            placeHolder='Enter your email' 
            showClearButton={false}
            readOnly={!doEditEmail}
          />
        )}
        {!isEmailVerified && (
          <div>
            <div className='my-4'> 
              Your emailadress <strong>{email}</strong> has not been confirmed yet. To confirm your email enter the code sent to your email adress and click <em>Confirm</em>. 
              Is this not your email adress? <button onClick={cancelConfirmEmail} className='text-primary1-base'>Change email</button>
            </div>
            <div>
              <Input 
                label="Confirmation code"
                onChange={confirmationCodeChange} 
                value={emailConfirmationCode} 
                placeHolder='Enter your confirmation code' 
                showClearButton={false}
              />
            </div>
          </div>
        )}
      </div>
      {isEmailVerified && (
        <div className='flex'>              
          {!doEditEmail && (
            <button onClick={editEmail} className='rounded bg-primary1-base text-white flex justify-center items-center p-2'>Update email</button>
          )}
          {doEditEmail && (
            <>
              <button onClick={saveEmail} className='rounded bg-primary1-base text-white flex justify-center items-center p-2 mr-2'>Save</button>
              <button onClick={cancelEditEmail} className='rounded bg-primary2-base text-white flex justify-center items-center p-2'>Cancel</button>
            </>
          )}
        </div>
      )}
      {!isEmailVerified && (
        <div className='flex justify-start items-center'>
          <button onClick={confirmEmail} className='rounded bg-primary1-base text-white flex justify-center items-center p-2 mr-2'>Confirm</button>
          <span>
            Did not get the code? <button onClick={sendConfirmationCodeAgain} className='text-primary1-base'>Send it again</button>
          </span>
        </div>
      )}
    </>
  )
  
  return (
    <Page>
      <div className='mb-4'>
        <h5>Email address</h5>
        <div className='grid grid-cols-2'>
          <div>
            {isEmailProvidedExternally && (
              <p>Your email is provided by an external provider and can not be changed.</p>
            )}
            {!isEmailProvidedExternally && emailMarkup}
          </div>
        </div>
      </div>
      <div className='border-t border-background1-base mt-4'>
        <h5>Password</h5>
        <div className='grid grid-cols-2'>
          <div>
            {isEmailProvidedExternally && (
              <p>Your password is handled by an external provider and can not be changed.</p>
            )}
            {!isEmailProvidedExternally && passwordMarkup}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default AccountPage;