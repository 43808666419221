import React, { useState, useEffect, useCallback } from 'react';

import { Paginator } from '../Paginator';

import { Select } from "../Select";
import { useQuery } from '@apollo/client';
import { useResult } from '../../hooks/useResult';

import { useSearchParams } from "react-router-dom";

import { VALUEBETS_FOR_COMMENCE_ENTRY } from '../../graphql/queries';

const ValuebetsForEvent = ({ event, betAmount, betCurrency }) => {

  const availableMarkets = [
    { value: 'h2h', label: 'Head 2 Head' },
    { value: 'overunder', label: 'Over under' },
    { value: 'asianhcp', label: 'Asian handicap' }
  ];

  const firstCommenceEntry = event.commenceEntries[0];
  const defaultMarketId = availableMarkets[0].value;
  const selectableMarkets = [...availableMarkets];

  let [searchParams, setSearchParams] = useSearchParams();

  const getParams = useCallback((searchParams) => {
    const page = parseInt(searchParams.get('page') || 1);
    const pageSize = parseInt(searchParams.get('pageSize') || 20);
    const marketId = searchParams.get('market') || defaultMarketId;
    const commenceEntryId = firstCommenceEntry.id;
    return({
      first: pageSize, pageSize, page, marketId, commenceEntryId
    });
  }, [defaultMarketId, firstCommenceEntry.id]);

  const [params, setParams] = useState(getParams(searchParams));

  const { loading: isLoading, refetch: refetchSurebets, data: valuebetsResult } = useQuery(VALUEBETS_FOR_COMMENCE_ENTRY, { variables : params });
  const valuebetsData = useResult(valuebetsResult, {});
  
  useEffect(()  => {
    const timeout = setTimeout(() => {
      setParams(getParams(searchParams));
    }, 250);
    return () => {
      clearTimeout(timeout);
    }
  }, [searchParams, getParams]);

  const selectedMarketChanged = (selectedMarket) => {
    searchParams.set('market', selectedMarket);
    setSearchParams(searchParams);
  }

  const gotoPage = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  const refresh = () => {
    refetchSurebets();
  }
  
  const renderBet = (valuebet, bet) => {
    return(
      <>
        <div key={bet.id} className="w-4/12 text-left p-1">
          {bet.bookmakerId}
        </div>
        <div key={bet.id} className="w-2/12 text-center p-1">
          {bet.name}
        </div>
        <div key={bet.id} className="w-2/12 text-center p-1">
          {bet.value}
        </div>
        <div key={bet.id} className="w-2/12 text-center p-1">
          {valuebet.average}
        </div>
        <div key={bet.id} className="w-2/12 text-center p-1">
          {valuebet.overvalue}%
        </div>
      </>
    );
  }

  return(
    <>
      <div className="mt-2 grid grid-cols-4 border rounded border-background2-base p-4">
        <Select
          label={'Market'}
          options={selectableMarkets}
          value={params.marketId}
          onChange={selectedMarketChanged}
        />
      </div>
      <div className='mt-4 mb-2'>
        <Paginator
          total={valuebetsData?.valuebets_for_commence_entry?.paginatorInfo?.total || 0} 
          page={params.page}
          pageSize={params.pageSize} 
          onGotoPage={gotoPage}
          onRefresh={refresh}
          loading={isLoading}
        />
      </div>
      <div className="mt-1 rounded border bg-background3-base border-background2-base p-2 text-text3-base flex flex-row">
        <span className="w-4/12 text-left">Bookmaker</span>
        <span className="w-2/12 text-center">Bet</span>
        <span className="w-2/12 text-center">Odds</span>
        <span className="w-2/12 text-center">Average</span>
        <span className="w-2/12 text-center">Overvalue</span>
      </div>
      {isLoading && (
        <div className="mt-2 rounded bg-white border-background3-base text-roboto text-text2-base">
          <div className="p-4 text-center">
            Loading...
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="mt-2 rounded bg-white border-background3-base text-roboto text-text2-base">
          {(valuebetsData?.valuebets_for_commence_entry?.paginatorInfo?.total) === 0 && (
            <div className="p-4 text-center">
              No surebets found for this market <strong>{params.marketId}</strong>
            </div>  
          )}
          {valuebetsData.valuebets_for_commence_entry?.data?.map(valuebet => {    
            return(
              <div key={valuebet.id} className="flex flex-row w-full border border-background2-base my-1 rounded p-1" >
                {valuebet.values.map((bet) => {
                  return(renderBet(valuebet, bet))  
                })}
              </div>
            )
          })}
        </div>
      )}
    </>
  )

}

export { ValuebetsForEvent }