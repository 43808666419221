import { useEffect, useRef } from "react";

const Input = ({value, label, placeHolder, onChange, showClearButton, readOnly, secure }) => {

  const inputRef = useRef(null);

  useEffect(() => {
    if(readOnly === false && inputRef.current) {
      console.log('fpocussing');
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [readOnly]);

  const clear = () => {
    onChange?.('');
  }

  const hasClearButton = showClearButton !== false;
  const isReadOnly = readOnly === true;
  const type = secure ? 'password' : 'text';

  return(
    <div>
      {label && (
        <div className="mb-1">
          <span className="text-text2-base">{label}</span>
        </div>
      )}
      <div className="flex justify-center border-border2-base bg-secondary2-transparent p-2 rounded">
        <input 
          ref={inputRef}
          type={type}
          className='w-full text-text2-base relative bg-transparent focus:outline-none' 
          placeholder={placeHolder}
          value={value} 
          onChange={e => onChange?.(e.target.value)} 
          readOnly={isReadOnly}
        />
        {hasClearButton && (
          <button 
            onClick={clear}
            className="text-text2-base text-xs"
          >
            clear
          </button>
        )}
      </div>
    </div>
  )

}

export { Input }