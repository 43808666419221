
import {
  gql
} from "@apollo/client";

export const eventFragment = gql`
  fragment eventFragment on Event {
    id
    isLive
    sport { 
      id
      name
    }
    league {
      id
      name
      sport {
        name
      } 
    }
    homeTeam {
      name
    }
    awayTeam {
      name
    }
    updatedAt
  }
`;

export const GET_ME = gql`
query me {
  me {
    id
    username
    favorites {
      id
      type
      relationId
    }
  }
}
`

export const USER_BY_USERNAME = gql`
query userByUsername($username: String!) {
  userByUsername(username: $username) {
    id
    username
  }
}
`

export const CREATE_USER = gql`
mutation createUser($username: String!) {
  createUser(username: $username) {
    id
    username
    favorites {
      id
      type
      relationId
    }
  }
}
`

export const BOOKMAKERS = gql`
  query bookmakers($query: String, $page: Int, $first: Int!) {
    bookmakers(first: $first, page: $page, query: $query) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        id
        name
        bookmakerId
        websiteUrl
        script
        scriptStatus
        lastSchedule
        lastScheduleRuntime
      }
    }
  }
`;

export const SPORTS = gql`
  query sports($market: String, $betType: String, $live: Boolean, $page: Int, $first: Int!) {
    sports(market: $market, betType: $betType, live: $live, page: $page, first: $first) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        name
      }
    }
  }
`;

export const LEAGUES = gql`
  query leagues($query: String, $market: String, $sports: [String], $betType: String, $live: Boolean, $page: Int, $first: Int!) {
    leagues(query: $query, market: $market, sports: $sports, betType: $betType, live: $live, page: $page, first: $first) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        sportId        
        name
      }
    }
  }
`;


export const VALUEBETS_BOOKIES = gql`
  query valuebetsBookmakers($live: Boolean, $minovervalue: Float, $market: String, $sports: [String], $orderBy: String, $query: String, $favorites: Boolean) {
    valuebetsBookmakers(live: $live, minovervalue: $minovervalue, market: $market, sports: $sports, orderBy: $orderBy, query: $query, favorites: $favorites)
  }
`;

export const VALUEBETS = gql`
  query valuebets($live: Boolean, $minovervalue: Float,$bookmakers: [String], $first: Int!, $page: Int, $market: String, $sports: [String], $orderBy: String, $query: String, $favorites: Boolean) {
    valuebets(live: $live, minovervalue: $minovervalue, bookmakers: $bookmakers, first: $first, page: $page, market: $market, sports: $sports, orderBy: $orderBy, query: $query, favorites: $favorites) {
      paginatorInfo {
        total
        count
        currentPage
      }
      data {
        id
        commenceDay
        commenceEntries {
          id
          numSurebets
          numValuebets
          best_valuebets {
            value
            overvalue
            average
            estimatedProbability
            bookmakerProbability
            marketId
            values {
              bookmakerId
              name
              value
              bookmaker {
                id
                websiteUrl
              }
            }
          }
          commenceTime
        }
        league {
          id
          name
        }
        sport {
          name
        }
        homeTeam {
          name
        }
        awayTeam {
          name
        }
      }
    }
  }
`;

export const SUREBETS = gql`
  query surebets($live: Boolean, $favorites: Boolean, $commenceDay: Date, $market: String, $bookmakers: [String], $leagues: [ID], $sports: [String], $orderBy: String, $query: String, $page: Int, $first: Int!) {
    surebets(live: $live, favorites: $favorites, commenceDay: $commenceDay, market : $market, bookmakers: $bookmakers, leagues: $leagues, sports: $sports, page: $page, first: $first, orderBy: $orderBy, query: $query) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        id
        isLive
        updatedAt
        sport { 
          id
          name
        }
        league {
          id
          name
          sport {
            name
          }
        }
        homeTeam {
          name
        }
        awayTeam {
          name
        }
        commenceEntries { 
          id
          commenceTime
          updatedAt
          best_surebets {
            id
            marketId
            profit
            values {
              id
              name
              bookmakerId
              value
              createdAt
              history {
                value
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;


export const SUREBETS_BOOKIES = gql`
  query surebetsBookmakers($live: Boolean, $favorites: Boolean, $commenceDay: Date, $market: String, $bookmakers: [String], $leagues: [ID], $sports: [String], $orderBy: String, $query: String) {
    surebetsBookmakers(live: $live, favorites: $favorites, commenceDay: $commenceDay, market : $market, bookmakers: $bookmakers, leagues: $leagues, sports: $sports, orderBy: $orderBy, query: $query)
  }
`;

export const EVENTS_BOOKIES = gql`
  query eventsBookmakers($live: Boolean, $favorites: Boolean, $market: String, $bookmakers: [String], $sports: [String], $leagues: [ID], $orderBy: String, $query: String) {
    eventsBookmakers(live: $live, favorites: $favorites, market : $market, bookmakers: $bookmakers, sports: $sports, leagues: $leagues, orderBy: $orderBy, query: $query)
  }
`;

export const EVENTS = gql`
  query events($live: Boolean, $favorites: Boolean, $market: String, $bookmakers: [String], $sports: [String], $leagues: [ID], $orderBy: String, $query: String, $page: Int!, $first: Int!) {
    events(live: $live, favorites: $favorites, market : $market, bookmakers: $bookmakers, sports: $sports, leagues: $leagues, page: $page, first: $first, orderBy: $orderBy, query: $query) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        ...eventFragment
        commenceEntries { 
          id
          commenceTime
          updatedAt
          best_h2h_odds {
            id
            name
            value
            oddsEntry {
              marketId
              bookmakerId
              bookmakerURI
            }
            history {
              id
              value
            }
          }
        }
      }
    }
  }
  ${eventFragment}
`;

export const EVENTS_ASIAN = gql`
  query events($live: Boolean, $favorites: Boolean, $market: String, $bookmakers: [String], $sports: [String], $leagues: [ID], $page: Int, $first: Int!, $orderBy: String, $query: String) {
    events(live: $live, favorites: $favorites, market : $market, bookmakers: $bookmakers, sports: $sports, leagues: $leagues, page: $page, first: $first, orderBy: $orderBy, query: $query) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        ...eventFragment
        commenceEntries { 
          id
          commenceTime
          updatedAt
          best_asianhcp_odds {
            id
            name
            value
            oddsEntry {
              marketId
              bookmakerId
              bookmakerURI
            }
            history {
              id
              value
            }
          }
        }
      }
    }
  }
  ${eventFragment}
`;

export const EVENTS_OVERUNDER = gql`
  query events($live: Boolean, $favorites: Boolean, $market: String, $bookmakers: [String], $sports: [String], $leagues: [ID], $page: Int, $first: Int!, $orderBy: String, $query: String) {
    events(live: $live, favorites: $favorites, market : $market, bookmakers: $bookmakers, sports: $sports, leagues: $leagues, page: $page, first: $first, orderBy: $orderBy, query: $query) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        ...eventFragment
        commenceEntries { 
          id
          commenceTime
          updatedAt          
          best_overunder_odds {
            id
            name
            value
            oddsEntry {
              marketId
              bookmakerId
              bookmakerURI
            }
            history {
              id
              value
            }
          }
        }
      }
    }
  }
  ${eventFragment}
`;

export const EVENT_DETAILS = gql`
  query event($id: ID!) {
    event(id: $id) {
      ...eventFragment
      commenceEntries { 
        id
        commenceTime
        updatedAt
        odds {          
          marketId
          bookmakerId
          bookmakerURI
          values {
            name
            value
          }          
        }
      }
    }
  }
  ${eventFragment}
`;

export const SUREBETS_FOR_COMMENCE_ENTRY = gql`
  query surebets_for_commence_entry($commenceEntryId: ID!, $marketId: ID!, $page: Int, $first: Int!) {
    surebets_for_commence_entry(commenceEntryId: $commenceEntryId, marketId: $marketId, page: $page, first: $first) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        id
        marketId
        profit
        values {
          id
          name
          bookmakerId
          value
          createdAt
        }
      }
    }
  }
`;

export const VALUEBETS_FOR_COMMENCE_ENTRY = gql`
  query valuebets_for_commence_entry($commenceEntryId: ID!, $marketId: ID!, $page: Int, $first: Int!) {
    valuebets_for_commence_entry(commenceEntryId: $commenceEntryId, marketId: $marketId, page: $page, first: $first) {
      paginatorInfo {
        total
        currentPage
        count
      }
      data {
        id
        marketId
        overvalue
        value
        average
        estimatedProbability
        bookmakerProbability
        values {
          name
          value
          bookmakerId
          bookmaker {
            bookmakerId
            name
            websiteUrl
          }
          createdAt
        }
      }
    }
  }
`;