
import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Page from '../components/Page';


const LeavePage = () => {

  const { uri } = useParams();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if(countdown === 0) {
      window.location.href = uri;
    } else {
      setTimeout(() => {
        setCountdown(countdown => countdown - 1);
      }, 1000);
    }
  }, [countdown, uri]);

  return(
    <Page showAds={false} backgroundColor={'transparent'}>
      <div className="h-full w-full flex justify-center items-center flex-col">
        <p>You are not leaving betfavor for:</p>
        <h4>
          <a rel="noreferrer" className='text-primary1-base underline' href={uri}>{uri}</a>
        </h4>
        <p className='text-primary1-base'>
          in {countdown} seconds, or click the link above.
        </p>
      </div>
    </Page>
  )
}

export default LeavePage;