import { useContext, useState } from "react";

import { Auth } from 'aws-amplify';

import TextInput from '../TextInput';

import { ReactComponent as GoogleIcon } from '../../images/google.svg';
import { ReactComponent as FacebookIcon } from '../../images/facebook.svg';
import { ReactComponent as EmailIcon } from '../../images/facebook.svg';
import { ReactComponent as CloseIcon } from '../../images/close.svg';
import { ReactComponent as PasswordIcon } from '../../images/lock.svg';
import ModalBase from './ModalBase';

import LoadingIcons from 'react-loading-icons'

import { AuthContext } from '../../AuthContext';

const SignupModal = () => {

  const { dispatch: dispatchAuthState } = useContext(AuthContext);

  const [username, setUsername] = useState('donagreja@gmail.com');
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification ] = useState('');
  const [signupError, setSignupError] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);

  async function googleSignIn() {
    Auth.federatedSignIn({provider: 'Google'});
  }
  
  async function facebookSignIn() {
    Auth.federatedSignIn({provider: 'Facebook'});
  }
  
  const performSignup = async () => {
    setSignupError(undefined);
    try {
      setIsSigningUp(true);
      if(password !== passwordVerification) throw new Error('Passwords do not match');
      await Auth.signUp({ username, password });
      setSignupError(undefined);
      setIsSigningUp(false);
      dispatchAuthState({ type: 'showConfirmationModal', value: true, email: username })
    } catch(e) {
      console.error(e)
      setIsSigningUp(false);
      setSignupError(e.message);
    }
  }

  const closeSignIn = () => {
    dispatchAuthState({ type : 'hideSignupModal' })
  }

  const signIn = () => {
    dispatchAuthState({ type : 'showLoginModal' })
  }

  return(
    <ModalBase>
      <div className="bg-white rounded-xl border border-secondary3-base shadow-lg p-8 relative">
        <button onClick={closeSignIn} className="absolute right-4 top-4">
          <CloseIcon width="24" />
        </button>
        <h4 className="text-text1-base">Lets get started</h4>
        <p>Already have an account? <button onClick={signIn} className="text-primary2-base">Log in</button></p>
        <div className="my-4 grid grid-cols-2 gap-4 mt-8">
          <button onClick={googleSignIn} className="bg-background3-base border border-background2-base p-3 rounded-lg text-text2-base flex justify-center items-center">
            <GoogleIcon width="24" className="mr-2" /> Login with Google
          </button>
          <button onClick={facebookSignIn} className="bg-facebookblue-base border border-background4-base p-3 rounded-lg text-white flex justify-center items-center">
            <FacebookIcon width="24" className="mr-2" /> Login with Facebook
          </button>
        </div>
        <div className="flex justify-center items-center text-text3-base font-roboto my-8">
          <div className="col-span-5 border-b w-48 mr-2 border-background1-base"></div>
          <div>or</div>
          <div className="col-span-5 border-b w-48 ml-2 border-background1-base"></div>
        </div>
        <div className="mb-4">
          <TextInput onChange={setUsername} value={username} placeHolder="email@domain.com" label="Email adress" icon={EmailIcon} />
        </div>
        <div className="mb-4">
          <TextInput onChange={setPassword} type="password"  value={password} placeHolder="Choose a secure password" label="Password" icon={PasswordIcon} />
        </div>
        <div className="mb-4">
          <TextInput onChange={setPasswordVerification} type="password" value={passwordVerification} placeHolder="Enter you password again" label="Password again" icon={PasswordIcon} />
        </div>
        {signupError && (
          <div className="text-secondary4-base my-2 bg-secondary3-transparent p-2">
            {signupError}
          </div>
        )}
        <div className="mt-4">
          <button onClick={performSignup} className="bg-primary1-base rounded-lg flex justify-center items-center text-white w-full p-2">
            {!isSigningUp && 'Create account'}
            {isSigningUp && (<LoadingIcons.Puff style={{ width : 24, height : 24, marginRight : 10 }} className='loading-icon' scale={0.2}/>)}
          </button>
        </div>
      </div>
    </ModalBase>  
  )
}

export { SignupModal }
