
const ModalBase = ({ children }) => {
  return(
    <>
      <div className="z-50 fixed left-0 top-0 w-full h-screen flex justify-center items-center text-text3-base bg-text1-base opacity-40">
        
      </div>
      <div className="z-50 fixed left-0 top-0 w-full h-screen flex justify-center items-center text-text3-base">
        {children}      
      </div>
    </>
  )
}

export default ModalBase;