import React, { useState, useContext, useEffect } from 'react';

import { SettingsContext } from '../SettingsContext';
import { Input } from './Input';
import { Select } from './Select';

const Settings = () => {
  
  const { state, dispatch } = useContext(SettingsContext);
  const [betAmount, setBetAmount] = useState(state.betAmount);
  const [betCurrency, setBetCurrency] = useState(state.betCurrency);

  const betAmountChanged = (value) => {    
    setBetAmount(value);
    const f = parseFloat(value);
    if(f) {
      dispatch({ type: 'setBetAmount', value: f });
    }
  }

  const betCurrencyChanged = (value) => {    
    setBetCurrency(value);
    dispatch({ type: 'setBetCurrency', value });
  }

  useEffect(() => {
    if(state.betAmount !== undefined) {
      setBetAmount(state.betAmount)
    }
  }, [state.betAmount])

  useEffect(() => {
    if(state.betCurrency !== undefined) {
      setBetCurrency(state.betCurrency)
    }
  }, [state.betCurrency])

  const availableCurrencies = [
    { value: 'EUR', label: 'EUR' },
    { value: 'USD', label: 'USD' },
    { value: 'GBP', label: 'GBP' }
  ]

  return(
    <div className='grid grid-cols-2 gap-10 p-4'>
      <div className='flex flex-col '>
        <Input 
          label="Bet amount"
          onChange={betAmountChanged} 
          value={betAmount} 
          placeHolder='Bet amount' 
        />
      </div>
      <div className='flex flex-col'>
        <Select 
          label="Currency"
          options={availableCurrencies}
          value={betCurrency}
          onChange={betCurrencyChanged}
        />
      </div>
    </div>
  )

}


const BetCalculator = () => {

}

BetCalculator.Settings = Settings;

export { BetCalculator }