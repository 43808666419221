import React from "react";
import Page from "../components/Page";

const TermsPage = () => {
    return(
        <>
            <Page showAds={true} backButton={true}>
                <h1 className='text-primary2-base'>Terms & Conditions for Using the Website</h1>
                <p>By accessing Betfavor.com or Betfavour.com, you agree to all the terms and conditions associated with the site. If you are under 18 years old or, as per the laws of your country, are under the legal gambling age, you should refrain from accessing or using this site. It is the user's responsibility to read and understand the terms and conditions, as we cannot accept that users on this website are unaware of the site's terms and conditions.</p>
                <h2>Copyright Information</h2>
                <p>All information on this website, including but not limited to text, videos, images, odds, and texts, is the property of Betfavor.com and Betfavour.com, and is subject to the copyright laws of Sweden. No content on this website should be reproduced in any way without our prior agreement.</p>
                <h2>Responsibility</h2>
                <p>We do not hold responsibility for any individual's gambling activities related to this website or any other website. Nor do we guarantee the accuracy and currency of the odds presented on this website. Users are responsible for their own gambling activities, with or without involving money. Any legal actions should be in accordance with the laws of Sweden.</p>
                <h2>Limitation of Liability</h2>
                <p>Betfavor.com and Betfavour.com are not gambling providers. If a user of this website enters into an agreement with a third party, such as but not limited to a bookmaker, online casino, or any other service mentioned on our website, any issues should be addressed directly with the third-party company.</p>
                <p>Betfavor.com and Betfavour.com shall not be held responsible for any losses, whether direct or indirect, perceived as a result of computer attacks, such as viruses or any other similar instances.</p>
                <p>By accessing this website, you fully accept responsibility and acknowledge potential risks. We will take all reasonable measures to minimize these risks, but we cannot be held accountable for matters beyond our control.</p>
                <h2>Liability for Content or Third-Party Content</h2>
                <p>Users are responsible for verifying the accuracy and currency of information concerning third-party sites displayed on this website. We provide this information for valid reasons, but we will not be held liable for outdated or incorrect information. If users utilize a third-party website, they are responsible for reviewing the terms and conditions of that website. We will not be held accountable for any losses incurred through third-party websites, regardless of the nature of those losses.</p>
                <p>If any content on this website violates European laws, we will promptly remove that material upon notification. However, we are not responsible for any content until we have been informed of its potential violation.</p>
                
                <p>Users of this website should also be aware that we do not encourage gambling, and if you experience any gambling-related problems, we strongly advise you to seek professional help.</p>
                <p>By accessing this website, you grant us permission to close your account if we detect any illegal activities or violations of our terms and conditions.</p>
                <h2>Payments</h2>
                <p>If a user purchases our subscription, it is the user's responsibility to cancel the subscription. We cannot be held responsible if a user is charged in the subsequent month due to forgetting to cancel an active subscription.</p>
                <p>The provider may change the subscription price at any time. In such a case, we will ensure that users are informed. If you already have a subscription, the new price will only apply during the next billing period. It's the user's responsibility to cancel the active subscription if they disagree with the new price.</p>
                <p>After canceling a subscription, the user agrees to pay the current subscription fee until the end of the current billing cycle.</p>
                <p>If you need to contact us, you can email us at <a href="mailto:support@betfavor.com">support@betfavor.com</a>, and we will respond as promptly as possible.</p>
            </Page>
        </>
    );
}

export default TermsPage;

/*



















*/