import React, { useState, useEffect } from 'react';

import { Select } from './Select';
import { Input } from './Input';

const SearchBar = ({ 
    markets,
    sortOptions,
    market, 
    marketChanged, 
    query, 
    queryChanged, 
    orderBy, 
    orderByChanged,
    tabs
  }) => {

  const [queryValue, setQueryValue] = useState(query);

  useEffect(() => {
    setQueryValue(query);
  }, [query]);
  
  const queryValueChanged = (value) => {
    queryChanged?.(value);
    setQueryValue(value);
  }

  const availableMarkets = markets || [
    { value: 'h2h', label: 'Head 2 Head' },
    { value: 'overunder', label: 'Over under' },
    { value: 'asianhcp', label: 'Asian handicap' }
  ]

  const availableSortOptions = sortOptions || [
    { value: 'profit desc', label: 'Profit' },
    { value: 'date asc', label: 'Date' }
  ]

  return(
    <div>
      {tabs && (
        <div className="w-full flex justify-start items-center border-b border-background1-base">
          {tabs.map((tab, index) => {
            const bg = tab.active ? 'bg-background1-base' : 'bg-background2-base';
            const text = tab.active ? 'text-text1-base' : 'text-text3-base';
            return(
              <div key={index} className={`p-2 px-3 ${bg} ${text} border-background1-base rounded-t border mr-1 border-b-0`}>
                <button onClick={tab.onClick}>{tab.label}</button>
              </div>
              )
          })}
        </div>
      )}
      <div className='bg-background3-base p-4 border-b border-background2-base'>
        <div className="bg-stone-400 grid grid-cols-3 gap-2">
            <Select 
              label={'Market'}
              options={availableMarkets} 
              value={market} 
              onChange={marketChanged}
            />
          <Input
            onChange={queryValueChanged}
            value={queryValue}
            label={'Search'}
            placeHolder={'Search'}
          />
          <Select
            label={'Sort by'}
            options={availableSortOptions}
            value={orderBy}
            onChange={orderByChanged}
          />        
        </div>
      </div>
    </div>
  );

}

export { SearchBar }