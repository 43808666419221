
import { Link } from 'react-router-dom';

import { ReactComponent as ClockIcon } from '../../images/clock.svg';
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg';

import { SportIcon } from '../SportIcon';
import { FavoriteIcon } from '../FavoriteIcon';

import { BestHead2HeadOdds } from './Head2Head';
import { BestOverUnderOdds } from './OverUnder';
import { BestAsianHandicapOdds } from './AsianHandicap';
import { UpdatedDate } from '../UpdatedDate';

import { camelCase } from '../../utils';

function BestOddsRow({ market, match, entry, onLeagueSelected}) {
  
  const { homeTeam, awayTeam, league, sport, updatedAt } = match;
  const { commenceTime } = entry;

  return(
    <div className='border-b border-background2-base'>
      <div>
        <div className="grid grid-cols-12 text-text2-base">
          <div className="col-span-6 grid grid-cols-12 text-text2-base p-2">
            <div className="col-span-1 flex justify-center items-center">
              <SportIcon sportId={sport.name} width="32" height="32" fill="#555555" />
            </div>
            <div className="col-span-11 flex justify-start items-center">
              <div className="flex justify-start flex-col ml-2 font-bold">
                <div>
                  <Link to={`/odds/${match.id}`} className="text-small text-black flex flex-row">
                    <span className='mr-1'>{homeTeam.name}</span> vs. <span className='ml-1'>{awayTeam.name}</span>
                  </Link>                  
                </div>
                <button 
                  onClick={e => onLeagueSelected(league)} 
                  className="text-xs font-normal text-left hover:underline hover:text-primary2-base"
                >
                  {camelCase(league.name)}
                </button>
              </div>
            </div>            
          </div>
          <div className="col-span-6 text-text2-base">
            {market === 'h2h' && (
              <BestHead2HeadOdds odds={entry.best_h2h_odds} />
            )}
            {market === 'overunder' && (
              <BestOverUnderOdds odds={entry.best_overunder_odds} />
            )}
            {market === 'asianhcp' && (
              <BestAsianHandicapOdds odds={entry.best_asianhcp_odds} />
            )}
          </div>
          <div className='col-span-12 bg-background2-base w-full p-2 flex'>
            <div className="col-span-1 flex justify-center items-center">
              <FavoriteIcon type="event" id={match.id} />
            </div>
            <div className="col-span-11 flex justify-start items-center whitespace-nowrap">
              <CalendarIcon width={19} height={19} fill="#687182" className="mx-1"/>
              {new Date(parseInt(commenceTime)).toLocaleDateString()}
              <ClockIcon width={19} height={19} fill="#687182" className="mx-1 ml-2"/>
              {new Date(parseInt(commenceTime)).toLocaleTimeString()}
              <UpdatedDate updatedAt={updatedAt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}


export { BestOddsRow }