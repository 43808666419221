
import {
  gql
} from "@apollo/client";

export const CREATE_FAVORITE = gql`
mutation createFavorite($type: String!, $relationId: ID!) {
  createFavorite(type: $type, relationId: $relationId) {
    id
  }
}
`;

export const REMOVE_FAVORITE = gql`
mutation removeFavorite($type: String!, $relationId: ID!) {
  removeFavorite(type: $type, relationId: $relationId) {
    id
  }
}
`;