import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Tabs } from '../components/menu/tabs';

const Page = ({
  children, 
  searchBar, 
  filterBar, 
  backButton, 
  backUrl,
  selectedTab,
  showTabs,
  showAds,
  backgroundColor
}) => {

  const navigate = useNavigate();

  const goBack = () => {
    navigate(backUrl || -1);
  }

  let colspan = 6;
  if(filterBar)
    colspan--;

  return (
    <div className="w-full flex flex-col">
      {showTabs !== false && (
        <div className="w-full bg-white flex justify-center items-center">
          <Tabs 
            selectedTab={selectedTab}
          />
        </div>
      )}
      <div className="w-full flex-grow flex ">
        <div className="content py-4">
          {backButton && (<button className='text-primary1-base my-1' onClick={goBack}>&laquo; Back</button>)}
          <div className="grid grid-cols-6 gap-4 h-full">
            {filterBar}
            <div className={`rounded-md flex justify-start ${'col-span-' + colspan}`}>
              <div className="flex-1">
                {searchBar && (
                  <div>
                    {searchBar}
                  </div>
                )}
                <div className={`h-full p-2 ${backgroundColor || 'bg-white'}`}>
                  {children}
                </div>
              </div>
              {showAds !== false && (
                <div className="ml-2 bg-background1-base flex justify-center items-center" style={{ width: 300 }}>
                  This is a ad banner
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page;