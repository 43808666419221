import React from 'react';
import { LiveOddsRow } from '../matchrows/LiveOddsRow';
import { Spinner } from '../Spinner';

const LiveOddsList = ({ 
  paginator, 
  events, 
  market, 
  loading, 
  filter,
  onLeagueSelected,
}) => {
    
  const loadingMarkup = (
    <div className='flex justify-center items-center p-12'>
      <Spinner />
    </div>
  )

  const emptyMarkup = (
    <div className='flex justify-center items-center p-12'>
      No entries found
    </div>
  );
    
  return(
    <>
      <div className='mb-2'>
        {paginator}
      </div>
      {loading && loadingMarkup}
      {!loading && events.length === 0 && emptyMarkup}
      {!loading && events.length > 0 && (
        <div>
          <div className='border border-b-0 border-background2-base rounded-tl-md rounded-tr-md'>
            <div className='grid grid-cols-12 p-2 text-text3-base font-bold'>
              <div className='col-span-6 px-1'>
                Match
              </div>
              {market === 'h2h' && (
                <div className='col-span-6 grid grid-cols-3 gap-2'>
                  <div className='text-center'>1</div>
                  <div className='text-center'>X</div>
                  <div className='text-center'>2</div>
                </div>
              )}
              {market === 'overunder' && (
                <div className='col-span-6 grid grid-cols-2 gap-2'>
                  <div className='text-center'>Over</div>
                  <div className='text-center'>Under</div>
                </div>
              )}
              {market === 'asianhcp' && (
                <div className='col-span-6 grid grid-cols-2 gap-2'>
                  <div className='text-center'>Home</div>
                  <div className='text-center'>Away</div>
                </div>
              )}
            </div>
          </div>
          <div className='border border-background2-base rounded-bl-md rounded-br-md'>
            {events.map((match) => {
              return(match.commenceEntries.map(commenceEntry => {
                return(
                  <LiveOddsRow 
                    key={`${commenceEntry.id}`} 
                    market={market} 
                    match={match} 
                    entry={commenceEntry}
                    filter={filter} 
                    onLeagueSelected={onLeagueSelected}
                  />
                )
              }));
            })}
          </div>
        </div>
      )}
    </>
  )
}

export { LiveOddsList }