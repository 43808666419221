
import { ReactComponent as FootballIcon } from '../images/sporticons/american_football.svg';
import { ReactComponent as AussieRulesIcon } from '../images//sporticons/aussie_rules.svg';
import { ReactComponent as BaseballIcon } from '../images//sporticons/baseball.svg';
import { ReactComponent as BasketballIcon } from '../images//sporticons/basketball.svg';
import { ReactComponent as BoxingIcon } from '../images//sporticons/boxing.svg';
import { ReactComponent as CricketIcon } from '../images//sporticons/cricket.svg';
import { ReactComponent as CyclingIcon } from '../images//sporticons/cycling.svg';
import { ReactComponent as EportsIcon } from '../images//sporticons/esports.svg';
import { ReactComponent as GolfIcon } from '../images//sporticons/golf.svg';
import { ReactComponent as HockeyIcon } from '../images//sporticons/hockey.svg';
import { ReactComponent as MMAIcon } from '../images//sporticons/mma.svg';
import { ReactComponent as QuestionMarkIcon } from '../images//sporticons/questionmark.svg';
import { ReactComponent as RugbyIcon } from '../images//sporticons/rugby.svg';
import { ReactComponent as SnookerIcon } from '../images//sporticons/snooker.svg';
import { ReactComponent as SoccerIcon } from '../images//sporticons/soccer.svg';
import { ReactComponent as TennisIcon } from '../images//sporticons/tennis.svg';
import { ReactComponent as VolleyBallIcon } from '../images//sporticons/volleyball.svg';

const iconMap = {
  football : FootballIcon,
  soccer : SoccerIcon,
  eports : EportsIcon,
  basketball : BasketballIcon,
  baseball : BaseballIcon,
  mma : MMAIcon,
  rugby : RugbyIcon,
  aussierules : AussieRulesIcon,
  icehockey : HockeyIcon,
  cricket : CricketIcon,
  boxing : BoxingIcon,
  tennis : TennisIcon,
  snooker : SnookerIcon,
  golf : GolfIcon,
  cycling : CyclingIcon,
  volleyball : VolleyBallIcon
}

const SportIcon = (props) => {
  const { sportId } = props;
  const Icon = typeof(iconMap[sportId]) !== 'undefined' ? iconMap[sportId] : (QuestionMarkIcon)
  let p = { fill : '#aaa', ...props }
  delete p.sportId;
  return(
    <>
      <Icon { ...p} title={sportId}/>
    </>
  )
}

export { SportIcon }