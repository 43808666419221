import { useContext, useState, useRef } from "react";

import { Auth } from 'aws-amplify';

import { ReactComponent as CloseIcon } from '../../images/close.svg';
import LoadingIcons from 'react-loading-icons'
import ModalBase from './ModalBase';

import { AuthContext } from '../../AuthContext';

const ConfirmUserEmailModal = () => {

  const { dispatch: dispatchAuthState, state: authState } = useContext(AuthContext);

  const code1ref = useRef(null);
  const code2ref = useRef(null);
  const code3ref = useRef(null);
  const code4ref = useRef(null);
  const code5ref = useRef(null);
  const code6ref = useRef(null);

  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [isConfirmingEmail, setIsConfirmingEmail] = useState(false);
  const [sendAgainVisible, setSendAgainVisible] = useState(false);

  const performConfirm = async () => {
    setConfirmationError(undefined);
    try {
      setIsConfirmingEmail(true);
      await Auth.confirmSignUp(authState.confirmationEmail, verificationCode);
      await Auth.signIn({ username: authState.confirmationEmail, password: authState.confirmationPassword });
      setConfirmationError(undefined);
      dispatchAuthState({ type : 'setIsAuthenticated', value : true });
      dispatchAuthState({ type: 'hideConfirmationModal' });
      setIsConfirmingEmail(false);
    } catch(e) {
      setIsConfirmingEmail(false);
      setConfirmationError(e.message);
      if(e.name === 'CodeMismatchException') {
        setSendAgainVisible(true);
      } else {
        setSendAgainVisible(false);
      }
    }
  }

  const closeVerification = () => {
    dispatchAuthState({ type : 'hideConfirmationModal' })
  }

  const code1 = verificationCode[0] || '';
  const code2 = verificationCode[1] || '';
  const code3 = verificationCode[2] || '';
  const code4 = verificationCode[3] || '';
  const code5 = verificationCode[4] || '';
  const code6 = verificationCode[5] || '';

  const resendEmail = () => {
    try {
      Auth.resendSignUp(authState.confirmationEmail);
      setConfirmationError('New confirmation code sent');
      setSendAgainVisible(false);
    } catch(e) {
      console.log('Error resending email', e.message);
    }
  }

  const appendToCode = (val, pos) => {
    if(val.toLowerCase() === 'backspace') {
      if(pos === 1) code1ref.current.focus();
      if(pos === 2) code2ref.current.focus();
      if(pos === 3) code3ref.current.focus();
      if(pos === 4) code4ref.current.focus();
      if(pos === 5) code5ref.current.focus();
      setVerificationCode(verificationCode.substring(0, pos));
      return;
    }
    if(Number.isNaN(parseInt(val))) return;
    if(verificationCode.length < pos) return;
    if(verificationCode.length > pos) {
      setVerificationCode(`${val}`);
    } else {
      setVerificationCode(`${verificationCode}${val}`);       
      if(pos === 0) code2ref.current.focus();
      if(pos === 1) code3ref.current.focus();
      if(pos === 2) code4ref.current.focus();
      if(pos === 3) code5ref.current.focus();
      if(pos === 4) code6ref.current.focus();
    }
  }

  const pasteCode = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    if(paste?.length === 6 && parseInt(paste) > 0) {
      setVerificationCode(paste);
      code6ref.current.focus();
    }
  }

  return(
    <ModalBase>
      <div className="bg-white rounded-xl border border-secondary3-base shadow-lg p-8 relative">
        <button onClick={closeVerification} className="absolute right-4 top-4">
          <CloseIcon width="24" />
        </button>
        <h4 className="text-text1-base">Confirm email</h4>
        <div className="mt-4">
          <p>
            A confirmation email has been sent to the emailaddress <strong>{authState.confirmationEmail || 'n/a'}</strong>.<br />
            Please enter the verification code below. Didn´t get it? <button onClick={resendEmail} className="text-textPrimary">Send the email again</button>
          </p>
        </div>
        <div className="flex justify-center items-center text-text3-base font-roboto my-4">
          <div className="col-span-5 border-b w-48 mr-2 border-background1-base"></div>
          <div>enter code</div>
          <div className="col-span-5 border-b w-48 ml-2 border-background1-base"></div>
        </div>
        <div className="mb-4 flex justify-center items-center">
          <input type="text" onChange={e => {}} onKeyDown={e => appendToCode(e.key, 0)} onPaste={pasteCode} ref={code1ref} className="text-lg text-text1-base border-2 border-text4-base rounded p-1 w-10 text-center mx-1" size={1} value={code1} />
          <input type="text" onChange={e => {}} onKeyDown={e => appendToCode(e.key, 1)} onPaste={pasteCode} ref={code2ref} className="text-lg text-text1-base border-2 border-text4-base rounded p-1 w-10 text-center mx-1" size={1} value={code2} />
          <input type="text" onChange={e => {}} onKeyDown={e => appendToCode(e.key, 2)} onPaste={pasteCode} ref={code3ref} className="text-lg text-text1-base border-2 border-text4-base rounded p-1 w-10 text-center mx-1" size={1} value={code3} />
          <input type="text" onChange={e => {}} onKeyDown={e => appendToCode(e.key, 3)} onPaste={pasteCode} ref={code4ref} className="text-lg text-text1-base border-2 border-text4-base rounded p-1 w-10 text-center mx-1" size={1} value={code4} />
          <input type="text" onChange={e => {}} onKeyDown={e => appendToCode(e.key, 4)} onPaste={pasteCode} ref={code5ref} className="text-lg text-text1-base border-2 border-text4-base rounded p-1 w-10 text-center mx-1" size={1} value={code5} />
          <input type="text" onChange={e => {}} onKeyDown={e => appendToCode(e.key, 5)} onPaste={pasteCode} ref={code6ref} className="text-lg text-text1-base border-2 border-text4-base rounded p-1 w-10 text-center mx-1" size={1} value={code6} />
        </div>
        {sendAgainVisible && (
          <div className="mb-4 flex justify-center items-center" v-if="confirmationError">
            <button onClick={resendEmail} className="text-red underline">Send a new code</button>
          </div>
        )}
        {confirmationError && (
          <div className="text-secondary4-base my-2 bg-secondary3-transparent p-2">
            {confirmationError}
          </div>
        )}
        <div className="mt-4">
          <button disabled={verificationCode?.length !== 6} onClick={performConfirm} className={`${ verificationCode?.length !== 6 ? 'bg-text2-base text-text3-base' : 'bg-primary1-base text-white'} rounded-lg flex justify-center items-center w-full p-2`}>
            {!isConfirmingEmail && 'Confirm'}
            {isConfirmingEmail && (<LoadingIcons.Puff style={{ width : 24, height : 24, marginRight : 10 }} className='loading-icon' scale={0.2}/>)}
          </button>
        </div>
      </div>
    </ModalBase>
  )
}

export { ConfirmUserEmailModal }
